import styles from './change.module.scss';

import Lock from 'assets/img/auth/lock.svg';

import Input from 'components/global/input/input';
import { FormEvent, useCallback, useState } from 'react';
import { api } from 'services/api/api';
import { isPasswordInvalid } from 'utils/isPasswordInvalid';
import { ChangeProps } from './change.types';

const Change = ({ setState, token }: ChangeProps) => {
    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');

    const [errors, setErrors] = useState({
        password: '',
        passwordAgain: '',
    });

    const validation = useCallback(() => {
        const errors = {};

        if (isPasswordInvalid(password))
            errors['password'] = 'Invalid password';

        if (!passwordAgain) errors['passwordAgain'] = 'Invalid password';
        else if (password !== passwordAgain)
            errors['passwordAgain'] = 'Passwords do not match';

        if (Object.keys(errors).length !== 0) {
            throw errors;
        }
    }, [password, passwordAgain]);

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            try {
                validation();

                api.user
                    .changePassword({
                        password1: password,
                        password2: passwordAgain,
                        rememberPasswordToken: token,
                    })
                    .then(() => {
                        setState('success');
                    })
                    .catch(() => {
                        //
                    });
            } catch (error) {
                setErrors(error);
            }
        },
        [password, passwordAgain, validation, setState, token],
    );

    return (
        <form className={styles.change} onSubmit={handleSubmit}>
            <div className={styles.changeTop}>
                <h1 className={styles.changeHeader}>New password</h1>
                <p className={styles.changeText}>
                    Provide a new password for your STC account.
                </p>
                <Input
                    placeholder="New Password"
                    change={setPassword}
                    prepend={Lock}
                    errorMessage={errors.password}
                    type="password"
                />
                <p className={styles.changeRequirements}>
                    Minimum 8 characters and at least one uppercase and
                    lowercase letter, one number and one of the following
                    symbols !@#$%^&*)(+=._-
                </p>
                <Input
                    placeholder="Repeat New Password"
                    change={setPasswordAgain}
                    prepend={Lock}
                    errorMessage={errors.passwordAgain}
                    type="password"
                />
            </div>
            <div className={styles.changeBottom}>
                <button>change password</button>
            </div>
        </form>
    );
};

export default Change;
