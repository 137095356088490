import { createContext } from 'react';
import { AuthStepsType, UserType } from 'views/overlay/overlay.types';

type UniversityContextTypes = {
    isLoggedIn: {
        isLoggedIn: boolean;
        setIsLoggedIn: (isLoggedIn: boolean) => void;
    };
    authModal: {
        showAuthModal: boolean;
        setShowAuthModal: (showAuthModal: boolean) => void;
        authModalStep: AuthStepsType;
        setAuthModalStep: (authModalStep: AuthStepsType) => void;
    };
    user: {
        user: UserType;
        setUser: (user: UserType) => void;
        userLoading: boolean;
    };
};

export const UniversityContext = createContext<Partial<UniversityContextTypes>>(
    {},
);
