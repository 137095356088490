import { PassedStagesResposeDTO } from 'services/generated/base';

export const requiredStages: PassedStagesResposeDTO['stages'] = [
    'kyc_completed',
    'tor_downloaded',
    'tor_uploaded',
    'terms_accepted',
    'redemption_acknowledge_accepted',
    'announcement_accepted',
];
