import { ContextType } from 'components/global/authModal/authModal.types';
import { UniversityContext } from 'contexts/universityContext';
import { AppContext } from './context';

export const getContext = (contextType: ContextType) => {
    switch (contextType) {
        case 'university':
            return UniversityContext;
        case 'app':
        default:
            return AppContext;
    }
};
