import CheckedImage from 'assets/img/checked.svg';
import UncheckedImage from 'assets/img/unchecked.svg';

import styles from './checkbox.module.scss';

type checkboxProps = {
    check: boolean;
    setCheck: (value: boolean) => void;
    checkError: boolean;
    message?: React.ReactNode;
    id?: string;
    name?: string;
};

const Checkbox = ({
    check,
    checkError,
    setCheck,
    message,
    id,
    name,
}: checkboxProps) => {
    return (
        <div className={styles.terms}>
            <input
                id={id ?? 'checkbox'}
                type="checkbox"
                className={styles.checkbox}
                checked={check}
                onChange={e => setCheck(e.target.checked)}
                name={name}
            />
            <label htmlFor={id ?? 'checkbox'} className={styles.checkboxLabel}>
                <img
                    src={check ? CheckedImage : UncheckedImage}
                    alt="checked"
                />
                <p
                    className={`${styles.checkboxText} ${
                        checkError ? styles.checkboxTextError : ''
                    }`}>
                    {message ?? (
                        <>
                            I accept the{' '}
                            <a
                                href="https://www.studentcoin.org/terms-of-use-wallet"
                                rel="noreferrer"
                                target="_blank">
                                STC Staking terms of use
                            </a>{' '}
                            and understand that my STC Tokens will be frozen.{' '}
                        </>
                    )}
                </p>
            </label>
        </div>
    );
};

export default Checkbox;
