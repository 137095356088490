import Button from 'components/global/button/button';
import { DelistContext } from 'contexts/delistContext';
import { RedeemFormContext } from 'contexts/redeemFormContext';
import { useContext } from 'react';
import { Checkbox } from '../checkbox/checkbox';
import { FormInfoModal } from '../formInfoModal/formInfoModal';
import { FormModal } from '../formModal/formModal';
import { FormModalContent } from '../formModal/formModalContent/formModalContent';
import { formModalHeading } from '../formModal/formModalHeading/formModalHeading';
import { FormSteps } from '../formSteps/formSteps';
import { RedeemTemplate } from '../template/redeemTemplate';
import { formBoxData } from './formBox.data';
import styles from './formBox.module.scss';

export const FormBox = () => {
    const { isFormPassed } = useContext(DelistContext);

    const {
        steps,
        modalId,
        handleOpenModal,
        handleCloseModal,
        handleFormSend,
        checkError,
        formError,
    } = useContext(RedeemFormContext);

    return (
        <RedeemTemplate>
            <h2>Dear Valued STC User,</h2>
            <p>
                We would like to inform you that Student Coin is currently
                undergoing the $STC Token redemption process and STC Wallet
                liquidation. All of your $STC Tokens and STC Wallet sub-holdings
                will be redeempt with USDC tokens at a rate above the current
                market price.{' '}
                <strong>
                    Please note that the first deadline for $STC Token
                    redemption (June 9th, 2024) has already passed.
                </strong>{' '}
                However, you can still claim 100% of your eligible USDC.
            </p>
            <p>
                By claiming your USDC right now, you are undergoing the late
                redemption process before the final deadline of October 9th,
                2024.{' '}
                <strong>
                    Be aware that late redemptions may take between 1 to 3
                    months for processing, and support during this period may be
                    limited.
                </strong>
            </p>
            <p>
                To redeem your STC Wallet holdings, please complete the
                following KYC and AML procedures. You will receive USDC tokens
                on the Ethereum network, which can be transferred to Metamask,
                Coinbase Wallet, or any other centralized exchange that supports
                USDC deposits.{' '}
                <strong>
                    The information you provide in the form below will not
                    affect the final amount of USDC you will receive.
                </strong>
            </p>
            <p>
                Thank you for being part of our journey. We wish you all the
                best.
            </p>
            <p>Sincerely,</p>
            <p>The Student Coin Team</p>
            {isFormPassed ? (
                <div className={styles.formSuccess}>
                    <h3>Success!</h3>
                    <span>
                        Your form was submitted successfully. Please complete
                        the steps below.
                    </span>
                </div>
            ) : (
                <>
                    <div>
                        {formBoxData.map(item => (
                            <Checkbox
                                type={item.type}
                                key={item.name}
                                text={item.text}
                                name={item.name}
                                checkboxes={item.checkboxes}
                                checkError={checkError}
                            />
                        ))}
                    </div>
                    <div className={styles.btnContainer}>
                        <Button
                            text="Send form"
                            type="primary"
                            onClick={handleFormSend}
                            className={styles.sendFormButton}
                        />
                        {formError && (
                            <span className={styles.error}>{formError}</span>
                        )}
                    </div>
                </>
            )}
            <div className={styles.stepsContainer}>
                <FormSteps data={steps} handleOpenModal={handleOpenModal} />
            </div>
            <FormModal
                isOpen={!!modalId}
                heading={formModalHeading(modalId)}
                handleClose={handleCloseModal}>
                <FormModalContent id={modalId} />
            </FormModal>
            <FormInfoModal />
        </RedeemTemplate>
    );
};
