import {
    MouseEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { AppContext } from 'utils/context';

import DeclinedImage from 'assets/img/resultImages/declined.svg';
import KYCImage from 'assets/img/resultImages/kyc.svg';
import ReviewImage from 'assets/img/resultImages/review.svg';
import SuccessImage from 'assets/img/resultImages/success.svg';
import Close from 'assets/img/x-gray.svg';

import { DelistContext } from 'contexts/delistContext';
import styles from './endScreen.module.scss';
import { EndScreenProps } from './endScreen.types';

const EndScreen = ({
    close,
    modalErrorText,
    tryAgain,
    type,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
EndScreenProps) => {
    const [data, setData] = useState(null);

    const { width } = useContext(AppContext);
    const { fetchPassedStages } = useContext(DelistContext);

    const widthValue = width;

    const success = useMemo(
        () => ({
            image: SuccessImage,
            header:
                widthValue >= 1000
                    ? 'Success! We’re processing your transaction'
                    : 'We’re processing your transaction',
            text: 'Your funds will be delivered shortly, this transaction usually takes about 10 minutes.',
            status: 'Pending',
            statusColor: '#F4B740',
            imgBg: '#DDFFEB',
        }),
        [widthValue],
    );

    const review = useMemo(
        () => ({
            image: ReviewImage,
            header:
                widthValue >= 1000
                    ? 'We’re nearly there. We just need to review your transaction'
                    : 'We just need to review your transaction',
            text: 'In order to ensure safety of the funds we are reviewing your transaction. Funds should be available shortly after the review.',
            status: 'In Review',
            statusColor: '#0D41E6',
            imgBg: '#FDE9C3',
        }),
        [widthValue],
    );

    const declined = useMemo(
        () => ({
            image: DeclinedImage,
            header:
                widthValue >= 1000
                    ? 'There’s some error at our end. Transaction could not be processed'
                    : 'Transaction could not be processed',
            text: 'There has been an error processing your transaction. Please try again in a moment.',
            status: 'Declined',
            statusColor: '#F44747',
            imgBg: '#ffefef',
        }),
        [widthValue],
    );

    const AuthCode = useMemo(
        () => ({
            image: DeclinedImage,
            header: 'Wrong 2FA authorization code, your request could not be processed',
            text: 'Your 2 Factor Authorization is incorrect, please make sure to paste a correct one.',
            status: 'Declined',
            statusColor: '#F44747',
            imgBg: '#ffefef',
        }),
        [],
    );

    const ChangePassword = useMemo(
        () => ({
            image: ReviewImage,
            header: 'Due to safety reasons, your request could not be processed.',
            text: 'After changing a password or email address, withdrawals are locked for 48 hours.',
            status: 'Change Password',
            statusColor: '#0D41E6',
            imgBg: '#FDE9C3',
            table: false,
        }),
        [],
    );

    useEffect(() => {
        if (type === 'success') setData(success);
        if (type === 'review') setData(review);
        if (type === 'declined') setData(declined);
        if (type === 'AuthCode') setData(AuthCode);
        if (type === 'ChangePassword') setData(ChangePassword);
    }, [type, success, review, declined, AuthCode, ChangePassword]);

    const date = useMemo(() => {
        const jsonDate = new Date().toJSON();
        const splited = jsonDate.split('T');
        const date = splited[0].split('-').reverse().join('.');
        const time = splited[1].split('.')[0].split(':').slice(0, 2).join(':');

        return `${date} ${time}`;
    }, []);

    const topModalHeight = useMemo(() => {
        if (type === 'ChangePassword') {
            if (width > 1000) return '130%';
            else return 'auto';
        }
    }, [type, width]);

    const handleClose = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            close(e);
            fetchPassedStages();
        },
        [close, fetchPassedStages],
    );

    return type ? (
        type === 'kyc' ? (
            <div className={styles.kyc}>
                <div className={styles.endScreenClose}>
                    <button onClick={close} className={styles.closeButton}>
                        <img src={Close} alt="escape" />
                    </button>
                </div>
                <div className={styles.kycImage}>
                    <img src={KYCImage} alt="kyc" />
                </div>

                <div className={styles.kycBottom}>
                    <div>
                        <h1>KYC verification required before the withdrawal</h1>
                        <p>
                            Due to an error your transaction has not been
                            submitted, try again.
                        </p>
                    </div>
                    <div className={styles.buttonContainer}>
                        <button
                            className={styles.kycButton}
                            style={{ margin: '0 auto', display: 'block' }}
                            onClick={e => close(e)}>
                            back to your wallet
                        </button>
                    </div>
                </div>
            </div>
        ) : (
            <div className={styles.endScreen}>
                <div className={styles.endScreenClose}>
                    <button onClick={close} className={styles.closeButton}>
                        <img src={Close} alt="escape" />
                    </button>
                </div>
                <div
                    className={styles.endScreenImage}
                    style={{
                        backgroundColor: data?.imgBg,
                        height: topModalHeight,
                    }}>
                    <img src={data?.image} alt="response" />
                </div>
                <div className={styles.endScreenBottom}>
                    <div className={styles.endScreenContainer}>
                        <div>
                            <h1 className={styles.endScreenHeader}>
                                {data?.header}
                            </h1>
                            <p className={styles.endScreenText}>
                                {modalErrorText || (
                                    <>
                                        You have successfully submitted a USDC
                                        transaction. Due to a high volume of
                                        transactions, a{' '}
                                        <strong>
                                            redemption can take up to a month
                                        </strong>
                                        . However, we will try to process it
                                        immediately. You will receive an email
                                        notification once the transaction is
                                        completed.{' '}
                                        <strong>
                                            No further action is required on
                                            your part.
                                        </strong>
                                    </>
                                )}
                            </p>
                        </div>
                        {data?.table !== false ? (
                            <div
                                className={`${styles.endScreenTable} ${styles[type]}`}>
                                <div
                                    className={`${styles.endScreenTableItem} ${styles.endScreenTableItemStatus}`}>
                                    <p>status</p>
                                    <p>
                                        <span
                                            className={
                                                styles.endScreenStatusDot
                                            }
                                            style={{
                                                backgroundColor:
                                                    data?.statusColor,
                                            }}></span>
                                        {data?.status}
                                    </p>
                                </div>
                                <div
                                    className={`${styles.endScreenTableItem} ${styles.endScreenTableItemDate}`}>
                                    <p>date</p>
                                    <p>{date}</p>
                                </div>
                            </div>
                        ) : null}
                    </div>
                    {type === 'declined' || type === 'AuthCode' ? (
                        <div className={styles.endScreenButtons}>
                            <button
                                className={styles.endScreenButton}
                                onClick={e => close(e)}>
                                back to your wallet
                            </button>
                            <button
                                className={styles.endScreenButtonBlue}
                                onClick={tryAgain}>
                                try again
                            </button>
                        </div>
                    ) : (
                        <div className={styles.buttonContainer}>
                            <button
                                className={styles.endScreenButtonBlue}
                                style={{ margin: '0 auto', display: 'block' }}
                                onClick={handleClose}>
                                back to your wallet
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    ) : null;
};

export default EndScreen;
