import styles from './redeemFooter.module.scss';
import { RedeemFooterProps } from './redeemFooter.types';

export const RedeemFooter = ({ customFooter }: RedeemFooterProps) => {
    return (
        <div className={styles.footer}>
            {customFooter ? (
                customFooter
            ) : (
                <>
                    <span>
                        <a
                            href="https://studentcoin.org"
                            target="_blank"
                            rel="noreferrer">
                            Token Reedemption Announcement
                        </a>
                    </span>
                    <span>
                        If you have any questions or need an assistance, please
                        visit our{' '}
                        <a
                            href="https://studentcoin.org/faq"
                            target="_blank"
                            rel="noreferrer">
                            FAQ
                        </a>
                        .
                    </span>
                </>
            )}
        </div>
    );
};
