import Logout from 'assets/img/mobileProfile/logout.svg';
import Logo from 'assets/img/new-logo.svg';
import { useLogout } from 'hooks/useLogout';
import styles from './reducedNavbar.module.scss';

export const ReducedNavbar = () => {
    const { logout } = useLogout();

    return (
        <nav className={styles.nav}>
            <div className={styles.logo}>
                <img src={Logo} alt="logo" /> Student Coin
            </div>
            <button className={styles.logoutBtn} onClick={logout}>
                <img src={Logout} alt="logout" className={styles.logoutImage} />
                <span>Sign Out</span>
            </button>
        </nav>
    );
};
