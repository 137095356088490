import ArrowLeft from 'assets/img/arrow-left-alt.svg';
import styles from './BackComponent.module.scss';
import { BackComponentProps } from './BackComponent.types';

export const BackComponent = ({
    backContent,
    clickBack,
}: BackComponentProps) => {
    if (backContent) {
        return (
            <button onClick={clickBack} className={styles.backContentButton}>
                {backContent}
            </button>
        );
    }

    return (
        <button className={styles.back} onClick={clickBack}>
            <img src={ArrowLeft} alt="arrow left" />
            <span>Back</span>
        </button>
    );
};
