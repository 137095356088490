import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { modalScreenTransition } from 'utils/pageVariants';

import Overlay from 'views/auth/overlay/overlay';
import Form from './form/form';
import Auth from './auth/auth';

import styles from './signIn.module.scss';

const SignIn = () => {
    const [state, setState] = useState('form');
    const [authorizationToken, setAuthorizationToken] = useState('');
    const [email, setEmail] = useState('');
    const [fromWebsite, setFromWebsite] = useState(false);

    const [animatedX, setAnimatedX] = useState(0);

    useEffect(() => {
        document.title = 'Student Coin - Sign In';
    });

    return (
        <Overlay>
            <AnimatePresence exitBeforeEnter>
                <motion.div
                    className={styles.signIn}
                    initial={{
                        opacity: 0,
                        x: animatedX,
                    }}
                    animate={{
                        opacity: 1,
                        x: 0,
                    }}
                    transition={modalScreenTransition}
                    key={state}>
                    {state === 'form' ? (
                        <Form
                            setState={setState}
                            setAuthorizationToken={setAuthorizationToken}
                            setEmail={setEmail}
                            setFromWebsite={setFromWebsite}
                            setAnimatedX={setAnimatedX}
                        />
                    ) : (
                        <Auth
                            setState={setState}
                            authorizationToken={authorizationToken}
                            email={email}
                            fromWebsite={fromWebsite}
                            setAnimatedX={setAnimatedX}
                        />
                    )}
                </motion.div>
            </AnimatePresence>
        </Overlay>
    );
};

export default SignIn;
