import { WithdrawViewProps } from './modal.types';
import Auth from './views/auth/auth';
import Details from './views/details/details';
import EndScreen from './views/endScreen/endScreen';
import Summary from './views/summary/summary';

export const View = ({
    address,
    amount,
    close,
    errors,
    fee,
    isLoading,
    modalErrorText,
    scannerShow,
    setAddress,
    setScannerShow,
    step,
    tryAgain,
    type,
    newWithdraw,
    email,
    check,
    setCheck,
    clearErrors,
}: WithdrawViewProps) => {
    switch (step.id) {
        case 'details':
            return (
                <Details
                    amount={amount}
                    address={address}
                    setAddress={setAddress}
                    scannerShow={scannerShow}
                    setScannerShow={setScannerShow}
                    errors={errors}
                    fee={fee}
                    checks={check}
                    setChecks={setCheck}
                    clearErrors={clearErrors}
                />
            );
        case 'summary':
            return <Summary amount={amount} address={address} fee={fee} />;
        case 'auth':
            return (
                <Auth
                    newWithdraw={newWithdraw}
                    isLoading={isLoading}
                    email={email}
                />
            );
        case 'endScreen':
            return (
                <EndScreen
                    type={type}
                    tryAgain={tryAgain}
                    close={close}
                    modalErrorText={modalErrorText}
                />
            );
    }
};
