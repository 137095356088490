import {
    ChangeEvent,
    ClipboardEvent,
    useCallback,
    useMemo,
    useState,
} from 'react';
import { InfoText } from './infoText/infoText';
import styles from './pinCode.module.scss';
import { PinCodeProps } from './pinCode.types';
import { checkIfAllFilled } from './utils/checkIfAllFilled';
import { handleKeyUp } from './utils/handleKeyUp';

const PinCode = ({ length, type, onReady }: PinCodeProps) => {
    const [values, setValues] = useState<string[]>(new Array(length).fill(''));

    const handleOnReady = useCallback(
        (array: string[]) => {
            if (checkIfAllFilled(array) && onReady) {
                onReady(array.join(''));
            }
        },
        [onReady],
    );

    const handleInput = useCallback(
        (e: ChangeEvent<HTMLInputElement>, index: number) => {
            const array = [...values];

            if (!e.target.value.match(/[0-9]/i)) {
                array[index] = '';
            } else {
                array[index] = e.target.value;
                handleKeyUp(undefined, index, 'next');
            }

            handleOnReady(array);

            setValues(array);
        },
        [handleOnReady, values],
    );

    const handlePaste = useCallback(
        (event: ClipboardEvent<HTMLInputElement>) => {
            const code = event.clipboardData
                .getData('Text')
                .trim()
                .split('')
                .map((char: string) => {
                    if (Number.isInteger(Number(char))) {
                        return parseInt(char);
                    }
                    return '';
                })
                .filter(Number.isInteger)
                .map(String);

            let toReturn = code;

            if (code.length > length) toReturn.length = length;

            if (code.length < length) {
                toReturn = [
                    ...code,
                    ...new Array(length - code.length).fill(''),
                ];
            }

            handleOnReady(toReturn);

            setValues(toReturn);
        },
        [handleOnReady, length],
    );

    const pinClasses = useMemo(() => {
        if (!type) return styles.pinCodeInput;
        else
            return `${styles.pinCodeInput} ${
                type === 'success'
                    ? styles.pinCodeInputSuccess
                    : styles.pinCodeInputError
            } `;
    }, [type]);

    return (
        <div className={styles.pinCodeWrapper}>
            <div className={styles.pinCode}>
                {values.map((value, index) => {
                    return (
                        <input
                            key={index}
                            type="text"
                            name="code"
                            maxLength={1}
                            value={value}
                            className={pinClasses}
                            onChange={e => handleInput(e, index)}
                            onKeyUp={e => handleKeyUp(e.keyCode, index, 'back')}
                            onPaste={handlePaste}
                        />
                    );
                })}
            </div>
            <InfoText type={type} />
        </div>
    );
};

export default PinCode;
