import BigNumber from 'bignumber.js';
import { RedeemStatusType } from 'components/redeem/button/redeemBtn.types';
import { ModalIdType } from 'components/redeem/content/RedeemFormContextProvider/RedeemFormContextProvider.types';
import { FormButtonType } from 'components/redeem/formSteps/formSteps.types';
import { Dispatch, SetStateAction, createContext } from 'react';
import { PassedStagesResposeDTO } from 'services/generated/base';
import { RedeemButtonType } from 'views/redeem/redeem.data';

type DelistContextTypes = {
    passedStages: PassedStagesResposeDTO['stages'];
    buttonStages: RedeemButtonType[];
    setButtonStages: Dispatch<SetStateAction<RedeemButtonType[]>>;
    fetchPassedStages: () => void;
    showStcLow: boolean;
    setShowStcLow: (value: boolean) => void;
    showError: boolean;
    setShowError: (value: boolean) => void;
    isStageFetched: boolean;
    setFormSubmitted: (value: boolean) => void;
    setFormStageStatus: (id: ModalIdType, status: RedeemStatusType) => void;
    formStages: FormButtonType[];
    isFormPassed: boolean;

    data: any;
    totalStc: BigNumber;
    email: string;
};

export const DelistContext = createContext<Partial<DelistContextTypes>>({});
