import AlertIcon from 'assets/img/alert-yellow.svg';
import ModalHeader from 'components/global/modal/header/header';
import { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal';
import styles from './formInfoModal.module.scss';

export const FormInfoModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const isModalClosed = localStorage.getItem('formInfoModal');
        if (!isModalClosed) {
            setIsOpen(true);
        }
    }, []);

    const handleClose = useCallback(() => {
        localStorage.setItem('formInfoModal', 'true');
        setIsOpen(false);
    }, []);

    return (
        <Modal
            isOpen={isOpen}
            className="modal-body"
            overlayClassName="modal-overlay"
            closeTimeoutMS={300}
            onRequestClose={handleClose}
            ariaHideApp={false}>
            <ModalHeader text="Important information" onClose={handleClose} />
            <div className={styles.body}>
                <img src={AlertIcon} alt="alert" className={styles.alert} />
                <p>
                    Your answers in the form will not affect the amount of funds
                    you will receive.
                </p>
            </div>
        </Modal>
    );
};
