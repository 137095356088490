import styles from './success.module.scss';

import { useHistory } from 'react-router-dom';

import PasswordSuccess from 'assets/img/auth/password-success.svg';
import { useCallback } from 'react';

const Success = () => {
    const history = useHistory();

    const redirectToSignIn = useCallback(() => {
        history.push({
            pathname: '/sign-in',
            state: { from: 'sign-up' },
        });
    }, [history]);

    return (
        <>
            <div className={styles.successTop}>
                <h1 className={styles.successHeader}>
                    Success, your password has been changed
                </h1>
                <img
                    src={PasswordSuccess}
                    className={styles.successImage}
                    alt="success"
                />
                <p className={styles.successText}>
                    You’ve succesfully changed your password.
                </p>
            </div>
            <div className={styles.successBottom}>
                <button onClick={() => redirectToSignIn()}>
                    continue to sign in
                </button>
            </div>
        </>
    );
};

export default Success;
