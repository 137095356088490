import styles from './mail.module.scss';

import ArrowLeft from 'assets/img/arrow-left-alt.svg';
import User from 'assets/img/auth/user.svg';

import Input from 'components/global/input/input';

import { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from 'services/api/api';
import { AppContext } from 'utils/context';

const Mail = () => {
    const history = useHistory();

    const { authModal } = useContext(AppContext);
    let showAuthModal = null;
    let setAuthModalStep = null;
    if (authModal) {
        showAuthModal = authModal.showAuthModal;
        setAuthModalStep = authModal.setAuthModalStep;
    }

    const [mail, setMail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [time, setTime] = useState(0);

    const handleSubmit = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setError('');

            api.user
                .forgotPassword({ email: mail.trim() })
                .then(() => {
                    setSuccess(true);
                })
                .catch(error => {
                    setSuccess(false);
                    if (error?.body.message) {
                        setError(error.body.message);
                    }
                });
            setTime(5);
        },
        [mail],
    );

    useEffect(() => {
        let timeout = null;
        const countDown = () => {
            if (time > 0) {
                setTime((time: number) => {
                    if (time > 0) time--;
                    else clearTimeout(timeout);
                    return time;
                });
            }
        };

        clearTimeout(timeout);
        timeout = setTimeout(() => countDown(), 1000);
        return () => clearTimeout(timeout);
    }, [time]);

    const handleBack = useCallback(() => {
        if (showAuthModal) setAuthModalStep('Sign in');
        else history.push('/sign-in');
    }, [history, setAuthModalStep, showAuthModal]);

    const handleChange = useCallback((mail: string) => {
        setMail(mail.replaceAll(' ', ''));
    }, []);

    return (
        <form className={styles.mail} onSubmit={handleSubmit}>
            <div className={styles.mailTop}>
                <h1 className={styles.mailHeader}>Reset password</h1>
                <p className={styles.mailText}>
                    We’ll send you an email to reset your password.
                </p>
                <Input
                    placeholder="Email"
                    type="email"
                    prepend={User}
                    change={handleChange}
                    value={mail}
                    errorMessage={error}
                />

                {!success && (
                    <p className={styles.mailText}>
                        After changing your password, you will not be able to
                        withdraw funds for 7 days.
                    </p>
                )}

                {success && !error ? (
                    <p
                        className={`${styles.mailInfo} ${styles.mailInfoSuccess}`}>
                        Please check your inbox. We just sent a password reset
                        link to provided address.
                    </p>
                ) : null}
            </div>

            <div className={styles.mailBottom}>
                <button
                    className={styles.back}
                    type="button"
                    onClick={() => handleBack()}>
                    <img src={ArrowLeft} alt="arrow left" />
                    <span>Back</span>
                </button>
                <button
                    type="submit"
                    disabled={time > 0}
                    className={`${styles.next} ${
                        mail === '' || time > 0 ? styles.nextDisabled : ''
                    }`}>
                    <span>send</span>
                </button>
            </div>
        </form>
    );
};

export default Mail;
