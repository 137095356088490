import { WithdrawStep } from '../modal.types';

export const getSteps = (wallet: string): WithdrawStep[] => {
    switch (wallet) {
        case 'lean':
        case 'smt':
        case 'orion':
        default:
            return [
                {
                    id: 'details',
                    text: 'Transaction details',
                },
                {
                    id: 'summary',
                    text: 'Redemption summary',
                },
                {
                    id: 'auth',
                    text: '2FA Authentication',
                },
                {
                    id: 'endScreen',
                    text: 'End Screen',
                },
            ];
    }
};
