import BigNumber from 'bignumber.js';
import { RedeemTooltip } from '../tooltip/redeemTooltip';
import styles from './redeemRow.module.scss';
import { RedeemRowProps } from './redeemRow.types';

export const RedeemRow = <T extends string | BigNumber>({
    title,
    value,
    tooltip,
    className,
    valueClassName,
    formatter,
}: RedeemRowProps<T>) => {
    return (
        <div className={`${styles.row} ${className}`}>
            <div className={styles.titleContainer}>
                <span>{title}</span>{' '}
                {tooltip && <RedeemTooltip tooltip={tooltip} />}
            </div>
            <span className={`${styles.value} ${valueClassName}`}>
                {formatter ? formatter(value) : value?.toString()}
            </span>
        </div>
    );
};
