import BigNumber from 'bignumber.js';

export const formatQuantity = (value: BigNumber) => {
    if (value === undefined || value === null) return '0';
    return value?.isInteger() ? value?.toFormat() : value?.toFormat(2);
};

export const formatStcPrice = (value: BigNumber) => {
    if (value === undefined || value === null) return '0';
    return `$${value?.toFormat(4)}`;
};

export const formatUsdcPrice = (value: BigNumber) => {
    if (value === undefined || value === null) return '0';
    return `$${value?.toFormat(4)}`;
};
