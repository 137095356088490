import { CancelablePromise, OpenAPI } from 'services/generated/base';
import { request } from 'services/generated/base/core/request';

export const uploadTor = (file: File): CancelablePromise<string> => {
    return request(OpenAPI, {
        method: 'POST',
        url: '/redemption/uploadToR',
        formData: {
            file,
        },
        mediaType: 'multipart/form-data',
    });
};
