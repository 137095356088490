import { LegacyRef, forwardRef } from 'react';
import styles from './footer.module.scss';

import { BackComponent } from './BackComponent/BackComponent';
import { NextComponent } from './NextComponent/NextComponent';
import { BottomProps } from './footer.types';

const Bottom = forwardRef(
    (
        {
            backContent,
            className,
            clickBack,
            clickNext,
            hideBack,
            hideNext,
            isLoading,
            nextContent,
            nextDisable,
            nextType,
            topContent,
        }: BottomProps,
        ref?: LegacyRef<HTMLDivElement>,
        // eslint-disable-next-line sonarjs/cognitive-complexity
    ) => {
        return (
            <div className={styles.footerContainer}>
                {topContent}
                <div
                    className={`${styles.footer} ${
                        backContent ? '' : styles.buttons
                    } ${className ?? ''}`}
                    ref={ref}>
                    {hideBack !== true ? (
                        <BackComponent
                            backContent={backContent}
                            clickBack={clickBack}
                        />
                    ) : null}
                    {hideNext !== true ? (
                        <NextComponent
                            clickNext={clickNext}
                            isLoading={isLoading}
                            nextDisable={nextDisable}
                            nextType={nextType}
                            nextContent={nextContent}
                        />
                    ) : null}
                </div>
            </div>
        );
    },
);

Bottom.displayName = 'Bottom';

export default Bottom;
