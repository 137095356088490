import { useCallback, useState } from 'react';

export const useStepper = <T>(initialStep: number, steps: T[]) => {
    const [step, setStep] = useState(initialStep);

    const goToNextStep = useCallback(() => {
        const nextStep = step + 1;
        if (nextStep < steps.length) {
            setStep(nextStep);
        }
    }, [step, steps.length]);

    const goToPreviousStep = useCallback(() => {
        const previousStep = step - 1;
        if (previousStep >= 0) {
            setStep(previousStep);
        }
    }, [step]);

    const goToStep = useCallback(
        (step: number) => {
            if (step >= 0 && step < steps.length) {
                setStep(step);
            }
        },
        [steps.length],
    );

    const resetStepper = useCallback(() => {
        setStep(initialStep);
    }, [initialStep]);

    return {
        stepNumber: step,
        currentStep: steps[step],
        goToNextStep,
        goToPreviousStep,
        goToStep,
        resetStepper,
    };
};
