/* eslint-disable react/prop-types */
import { MouseEvent, ReactNode, useCallback, useMemo } from 'react';
import styles from './button.module.scss';

type buttonProps = {
    text: string | ReactNode;
    to?: string;
    type?: string;
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;
    className?: string;
};

const Button = ({ onClick, text, className, to, type }: buttonProps) => {
    const handleClick = useCallback(
        (e: MouseEvent<HTMLDivElement>) => {
            onClick(e);
        },
        [onClick],
    );

    const customClassName = useMemo(
        () => (type ? styles[`${type}`] : ''),
        [type],
    );

    return (
        <>
            {onClick ? (
                <div
                    className={`${styles.button} ${customClassName} ${className}`}
                    onClick={handleClick}>
                    {text}
                </div>
            ) : (
                <a
                    href={to}
                    className={`${styles.button} ${customClassName} ${className}`}>
                    {text}
                </a>
            )}
        </>
    );
};

export default Button;
