import ArrowDown from 'assets/img/arrow-down.svg';
import { useCallback, useState } from 'react';
import { RedeemRow } from '../row/redeemRow';
import { RedeemTable } from '../table/redeemTable';
import styles from './redeemData.module.scss';
import { RedeemDataProps } from './redeemData.types';
import { formatQuantity, formatUsdcPrice } from './redeemData.utils';

export const RedeemData = ({ data, totalStc }: RedeemDataProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleClick = useCallback(() => {
        setIsDropdownOpen(prev => !prev);
    }, []);

    return (
        <div className={styles.container}>
            <div>
                <RedeemRow
                    title="Total STC to Redeem"
                    value={totalStc}
                    formatter={formatQuantity}
                />
                <RedeemRow
                    title="2 Years Average Token Price"
                    value={'$0.0034'}
                />
                <RedeemRow
                    title="Your Aggregated Redemption Price"
                    value={data?.averagePrice}
                    valueClassName={styles.value}
                    formatter={formatUsdcPrice}
                />
                <RedeemRow
                    title="Total USDC To Claim"
                    value={data?.usdTotal}
                    formatter={formatUsdcPrice}
                    valueClassName={styles.value}
                    className={styles.highlightRow}
                />
            </div>
            <div className={styles.dropdownBtnRow}>
                <button className={styles.dropdownBtn} onClick={handleClick}>
                    <img src={ArrowDown} alt="arrow down" />
                </button>
            </div>
            <div
                className={`${styles.showDropdown} ${
                    !isDropdownOpen ? styles.hideDropdown : ''
                }`}>
                <RedeemTable data={data} totalStc={totalStc} />
            </div>
        </div>
    );
};
