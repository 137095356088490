import { DelistContext } from 'contexts/delistContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'services/api/api';
import { PassedStagesResposeDTO } from 'services/generated/base';
import { makeCancelablePromise } from 'utils/cancelablePromise';
import { errorOnStatus } from 'utils/errorOnStatus';
import { useData, useTest } from './DelistContextProvider.hooks';
import { DelistContextProps } from './DelistContextProvider.types';

export const DelistContextProvider = ({ children }: DelistContextProps) => {
    const [passedStages, setPassedStages] = useState<
        PassedStagesResposeDTO['stages']
    >([]);

    const [showStcLow, setShowStcLow] = useState(false);
    const [showError, setShowError] = useState(false);
    const [isStageFetched, setIsStageFetched] = useState(false);
    const {
        data,
        totalStc,
        email,
        loading: dataLoading,
    } = useData(isStageFetched, setShowStcLow, setShowError);

    const { formStages, isFormPassed, setStages, setStatus } = useTest();

    const fetchPassedStages = useCallback(() => {
        const cancelablePromise = makeCancelablePromise(
            api.delist.passedStages(),
        );

        cancelablePromise
            .then(res => {
                errorOnStatus(res.value.status, setShowError);
                setPassedStages(res.value.stages);
                setStages(res.value.stages);
                setIsStageFetched(true);
            })
            .catch(() => {
                setShowError(true);
            });

        return cancelablePromise;
    }, [setStages]);

    useEffect(() => {
        const cancelablePromise = fetchPassedStages();

        return () => {
            cancelablePromise.cancel();
        };
    }, [fetchPassedStages]);

    const obj = useMemo(
        () => ({
            passedStages,
            fetchPassedStages,
            showStcLow,
            setShowStcLow,
            showError,
            setShowError,
            isStageFetched: !isStageFetched || dataLoading,
            formStages,
            isFormPassed,
            setFormStageStatus: setStatus,

            data,
            totalStc,
            email,
        }),
        [
            fetchPassedStages,
            passedStages,
            setShowStcLow,
            showStcLow,
            showError,
            setShowError,
            isStageFetched,
            isFormPassed,
            formStages,
            setStatus,
            data,
            totalStc,
            email,
            dataLoading,
        ],
    );

    return (
        <DelistContext.Provider value={obj}>{children}</DelistContext.Provider>
    );
};
