import { CheckboxNameType } from '../content/RedeemFormContextProvider/RedeemFormContextProvider.types';

const YES = 'Yes';
const NO = 'No';
const DO_NOT_REMEMBER = 'I do not remember';

export type FormBoxType = {
    text?: string;
    type?: 'radio' | 'checkbox';
    name: CheckboxNameType;
    checkboxes: {
        label: string;
        id: string;
        inputPlaceholder?: string;
        inputId?: 'mail' | 'tickets';
        maxInputCount?: number;
        sendValue?: string;
    }[];
};

export const formBoxData: FormBoxType[] = [
    {
        text: 'Have you participated in the STC Launchpad?',
        name: 'launchpad',
        checkboxes: [
            {
                label: NO,
                id: 'launchpad-1',
            },
            {
                label: YES,
                id: 'launchpad-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'launchpad-3',
            },
        ],
    },
    {
        text: 'How many bonus $STC tokens did you receive when purchasing $STC at the STC Launchpad?',
        name: 'bonusTokens',
        checkboxes: [
            {
                label: '5%',
                id: 'bonusTokens-1',
            },
            {
                label: '10%',
                id: 'bonusTokens-2',
            },
            {
                label: '20%',
                id: 'bonusTokens-3',
            },
            {
                label: '30%',
                id: 'bonusTokens-4',
            },
            {
                label: '50% (maximum bonus)',
                id: 'bonusTokens-5',
                sendValue: '50%',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'bonusTokens-6',
            },
            {
                label: 'I did not buy any $STC during STC Launchpad',
                id: 'bonusTokens-7',
                sendValue: 'I did not buy any tokens',
            },
        ],
    },
    {
        text: 'Have you participated in the STC Launchpad as an affiliate?',
        name: 'affiliate',
        checkboxes: [
            {
                label: NO,
                id: 'affiliate-1',
            },
            {
                label: YES,
                id: 'affiliate-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'affiliate-3',
            },
        ],
    },
    {
        text: 'Have you received any bonus $ETH for participating in the STC Launchpad affiliation?',
        name: 'bonusEth',
        checkboxes: [
            {
                label: NO,
                id: 'bonusEth-1',
            },
            {
                label: YES,
                id: 'bonusEth-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'bonusEth-3',
            },
        ],
    },
    {
        text: 'Have you received any bonus $STC for participating in the STC Launchpad affiliation?',
        name: 'bonusStc',
        checkboxes: [
            {
                label: NO,
                id: 'bonusStc-1',
            },
            {
                label: YES,
                id: 'bonusStc-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'bonusStc-3',
            },
        ],
    },
    {
        text: 'Have you received any $STC as an airdrop?',
        name: 'airdrop',
        checkboxes: [
            {
                label: NO,
                id: 'airdrop-1',
            },
            {
                label: YES,
                id: 'airdrop-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'airdrop-3',
            },
        ],
    },
    {
        text: 'Have you deposited any of your $STC into the STC Wallet from an external source?',
        name: 'externalSource',
        checkboxes: [
            {
                label: NO,
                id: 'externalSource-1',
            },
            {
                label: YES,
                id: 'externalSource-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'externalSource-3',
            },
        ],
    },
    {
        text: 'Have participated in the Premium Holder Program?',
        name: 'premiumHolder',
        checkboxes: [
            {
                label: NO,
                id: 'premiumHolder-1',
            },
            {
                label: YES,
                id: 'premiumHolder-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'premiumHolder-3',
            },
        ],
    },
    {
        text: 'Have you participated in the DAO Voting at STC Wallet?',
        name: 'daoVoting',
        checkboxes: [
            {
                label: NO,
                id: 'daoVoting-1',
            },
            {
                label: YES,
                id: 'daoVoting-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'daoVoting-3',
            },
        ],
    },
    {
        text: 'Have you used the STC Converter feature in the STC Wallet?',
        name: 'converter',
        checkboxes: [
            {
                label: NO,
                id: 'converter-1',
            },
            {
                label: YES,
                id: 'converter-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'converter-3',
            },
        ],
    },
    {
        text: 'Please indicate all groups of $STC tokens that you have possessed in your STC Wallet.',
        name: 'stcTokens',
        type: 'checkbox',
        checkboxes: [
            {
                label: 'Group 1 - $STC Tokens purchased during STC Launchpad and held in STC Wallet',
                id: 'stcTokens-1',
                sendValue: 'group-1',
            },
            {
                label: 'Group 2 - $STC Tokens purchased before or after STC Launchpad and held in STC Wallet',
                id: 'stcTokens-2',
                sendValue: 'group-2',
            },
            {
                label: 'Group 3 - $STC tokens from STC Affiliate Program',
                id: 'stcTokens-3',
                sendValue: 'group-3',
            },
            {
                label: 'Group 4 - $STC received from Premium Holder Program in STC Wallet',
                id: 'stcTokens-4',
                sendValue: 'group-4',
            },
            {
                label: 'Group 5 - $STC Tokens received from airdrops held in STC Wallet',
                id: 'stcTokens-5',
                sendValue: 'group-5',
            },
            {
                label: 'Group 6 - $STC deposited into STC Wallet from any other source',
                id: 'stcTokens-6',
                sendValue: 'group-6',
            },
        ],
    },
    {
        text: 'Have you participated in the $SMT Launchpad?',
        name: 'smtLaunchpad',
        checkboxes: [
            {
                label: NO,
                id: 'smtLaunchpad-1',
            },
            {
                label: YES,
                id: 'smtLaunchpad-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'smtLaunchpad-3',
            },
        ],
    },
    {
        text: 'Have you received $SMT as an airdrop?',
        name: 'smtAirdrop',
        checkboxes: [
            {
                label: NO,
                id: 'smtAirdrop-1',
            },
            {
                label: YES,
                id: 'smtAirdrop-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'smtAirdrop-3',
            },
        ],
    },
    {
        text: 'Have you participated in the $LEAN Launchpad?',
        name: 'leanLaunchpad',
        checkboxes: [
            {
                label: NO,
                id: 'leanLaunchpad-1',
            },
            {
                label: YES,
                id: 'leanLaunchpad-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'leanLaunchpad-3',
            },
        ],
    },
    {
        text: 'Have you received $LEAN as an airdrop?',
        name: 'leanAirdrop',
        checkboxes: [
            {
                label: NO,
                id: 'leanAirdrop-1',
            },
            {
                label: YES,
                id: 'leanAirdrop-2',
            },
            {
                label: DO_NOT_REMEMBER,
                id: 'leanAirdrop-3',
            },
        ],
    },
    {
        text: 'Please indicate all groups of tokens that you have possessed in your STC Wallet.',
        name: 'tokens',
        type: 'checkbox',
        checkboxes: [
            {
                label: '$SMT - from Launchpad',
                id: 'tokens-1',
                sendValue: 'smt-launchpad',
            },
            {
                label: '$SMT - from airdrop',
                id: 'tokens-2',
                sendValue: 'smt-airdrop',
            },
            {
                label: '$SMT - from Premium Holder Program',
                id: 'tokens-3',
                sendValue: 'smt-php',
            },
            {
                label: '$LEAN - from Launchpad',
                id: 'tokens-4',
                sendValue: 'lean-launchpad',
            },
            {
                label: '$LEAN - from airdrop',
                id: 'tokens-5',
                sendValue: 'lean-airdrop',
            },
            {
                label: '$USDP - from STC Converter',
                id: 'tokens-6',
                sendValue: 'usdp-converter',
            },
            {
                label: '$USDC - from STC Converter',
                id: 'tokens-7',
                sendValue: 'usdc-converter',
            },
            {
                label: '$ETH - from STC Launchpad affiliation',
                id: 'tokens-8',
                sendValue: 'eth-affiliate',
            },
        ],
    },
    {
        text: 'Have you contacted our support at any time?',
        name: 'support',
        checkboxes: [
            {
                label: NO,
                id: 'support-1',
            },
            {
                label: YES,
                id: 'support-2',
                inputPlaceholder: 'Please type support ticket number',
                inputId: 'tickets',
            },
        ],
    },
];
