import styles from './auth.module.scss';

import ArrowLeft from 'assets/img/arrow-left-alt.svg';
import Info from 'assets/img/auth/info.svg';
import Spinner from 'assets/img/auth/spinner.svg';
import axios from 'axios';

import PinCode from 'components/global/pinCode/pinCode';
import { CodeType } from 'components/global/pinCode/pinCode.types';
import Resend from 'components/global/resend/resend';

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { api, setToken } from 'services/api/api';
import { makeCancelablePromise } from 'utils/cancelablePromise';
import { getContext } from 'utils/getContext';
import { AuthProp } from './auth.types';

const Auth = ({
    setState,
    authorizationToken,
    email,
    className = '',
    contextType = 'app',
    fromWebsite,
}: AuthProp) => {
    const history = useHistory();

    const { authModal, isLoggedIn } = useContext(getContext(contextType));
    let showAuthModal = null;
    let setAuthModalStep = null;
    let setShowAuthModal = null;

    if (authModal) {
        showAuthModal = authModal.showAuthModal;
        setShowAuthModal = authModal.setShowAuthModal;
        setAuthModalStep = authModal.setAuthModalStep;
    }

    const [codeType, setCodeType] = useState<CodeType>(null);
    const [time, setTime] = useState(0);

    const handleOnReady = useCallback(
        (code: string) => {
            setCodeType(null);

            api.signIn
                .verify({
                    code,
                    authorizationToken,
                })
                .then(response => {
                    localStorage.setItem('token', `${response.token}`);

                    axios.defaults.headers.common[
                        'Authorization'
                    ] = `Bearer ${response.token}`;

                    setToken(response.token);

                    setCodeType('success');
                    setTimeout(() => {
                        if (showAuthModal) {
                            setShowAuthModal(false);
                            setAuthModalStep('Sign in');
                            isLoggedIn.setIsLoggedIn(true);
                        } else {
                            if (fromWebsite) {
                                window.location.href = `${process.env.REACT_APP_LAUNCHPAD}?auth=success`;
                                return;
                            }

                            history.push({
                                pathname: `/`,
                            });
                        }
                    }, 1000);
                })
                .catch(() => {
                    setCodeType('error');
                });
        },
        [
            history,
            isLoggedIn,
            authorizationToken,
            fromWebsite,
            setAuthModalStep,
            setShowAuthModal,
            showAuthModal,
        ],
    );

    const resend = useCallback(() => {
        const cancelablePromise = makeCancelablePromise(
            api.signIn.resend({ authorizationToken }),
        );

        cancelablePromise
            .then(() => {
                setTime(120);
            })
            .catch(error => {
                setTime(error.error.body.canResendNewCodeIn);
            });

        return () => {
            cancelablePromise.cancel();
        };
    }, [authorizationToken]);

    useEffect(() => {
        const cancel = resend();

        return () => cancel();
    }, [resend]);

    const protectedEmail = useMemo(() => {
        let value = email;
        const splitted = value.split('@');
        value = `${splitted[0][0]}***@${splitted[1]}`;

        return value;
    }, [email]);

    const handleBackButton = useCallback(() => {
        setState('form');
    }, [setState]);

    return (
        <div className={`${styles.auth} ${className}`}>
            <div className={styles.top}>
                <h1 className={styles.authHeader}>
                    Confirm your email by typing the 6-digit verification code
                    sent to&nbsp;
                    {protectedEmail}
                </h1>
                <PinCode length={6} type={codeType} onReady={handleOnReady} />
            </div>
            <div className={styles.bottom}>
                {codeType === 'success' ? (
                    <img
                        className={styles.bottomSpinner}
                        src={Spinner}
                        alt="spinner"
                    />
                ) : (
                    <Resend
                        resendFunction={resend}
                        time={time}
                        setTime={setTime}
                    />
                )}

                <div className={styles.authFooter}>
                    <button
                        className={styles.authFooterBack}
                        onClick={() => handleBackButton()}>
                        <img src={ArrowLeft} alt="back" />
                        <span>Back</span>
                    </button>

                    <div className={styles.authFooterInfo}>
                        <img
                            src={Info}
                            alt="info"
                            data-tip
                            data-for="tooltip"
                        />
                        <ReactTooltip
                            id="tooltip"
                            effect="solid"
                            className={styles.tooltip}>
                            <span style={{ width: '50px' }}>
                                If you don’t see this email in your inbox after
                                a few moments, check your junk email folder. If
                                the email has been marked as junk, mark it as
                                “Not junk” to avoid missing other
                                communications.
                            </span>
                        </ReactTooltip>
                        <span className={styles.authFooterInfoText}>
                            Didn’t receive our message?
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Auth;
