import styles from './form.module.scss';

import EyeSlash from 'assets/img/auth/eye-slash.svg';
import Eye from 'assets/img/auth/eye.svg';
import Lock from 'assets/img/auth/lock.svg';
import User from 'assets/img/auth/user.svg';

import Input from 'components/global/input/input';

import {
    FormEvent,
    MouseEvent,
    useCallback,
    useContext,
    useState,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { api } from 'services/api/api';
import { useQuery } from 'utils/customeHooks';
import { getContext } from 'utils/getContext';
import { FormProps } from './form.types';

const Form = ({
    setAuthorizationToken,
    setEmail,
    setState,
    contextType = 'app',
    recaptchaHidden,
    setFromWebsite,
}: FormProps) => {
    const query = useQuery();

    const { authModal } = useContext(getContext(contextType));
    let showAuthModal = null;
    let setAuthModalStep = null;
    if (authModal) {
        showAuthModal = authModal.showAuthModal;
        setAuthModalStep = authModal.setAuthModalStep;
    }

    const [inputEmail, setInputEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const location: any = useLocation();

    const appendClick = useCallback(() => {
        setShowPassword(!showPassword);
    }, [showPassword]);

    const handleSubmit = useCallback(
        async (e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setError('');
            if (!inputEmail || !password) {
                setError('Incorrect email or password! Try again.');
                return;
            }

            api.signIn
                .start({ email: inputEmail, password, recaptcha: '' })
                .then(response => {
                    setAuthorizationToken(response.authorizationToken);
                    setEmail(inputEmail);
                    setState('auth');
                    if (query.get('from') === 'launchpad') {
                        setFromWebsite(true);
                    }
                })
                .catch(error => {
                    if (
                        error?.body?.message ===
                        '.email should match format "email"'
                    ) {
                        setError('Invalid email format');
                    } else {
                        setError(error?.body?.message);
                    }
                });
        },
        [
            inputEmail,
            password,
            query,
            setAuthorizationToken,
            setEmail,
            setState,
            setFromWebsite,
        ],
    );

    const handleModalChangeView = useCallback(
        (e: MouseEvent<HTMLAnchorElement>, route: string) => {
            e.preventDefault();

            setAuthModalStep(route);
        },
        [setAuthModalStep],
    );

    return (
        <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formTop}>
                <h1 className={styles.formHeader}>Sign In</h1>
                {/* <p className={styles.formText}>
                    You don’t have an account?
                    {showAuthModal ? (
                        <a
                            href="/"
                            onClick={e => handleModalChangeView(e, 'Sign up')}>
                            &nbsp;Sign up
                        </a>
                    ) : (
                        <Link to="/sign-up"> Sign Up</Link>
                    )}
                </p> */}
                <Input
                    placeholder="Email"
                    margin={`32px 0`}
                    change={setInputEmail}
                    error={error ? true : false}
                    prepend={User}
                />
                <Input
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    margin={`0 0 10px 0`}
                    append={showPassword ? Eye : EyeSlash}
                    appendClick={appendClick}
                    change={setPassword}
                    prepend={Lock}
                    error={error ? true : false}
                />
                {showAuthModal ? (
                    <a
                        href="/"
                        className={styles.forgotPassword}
                        onClick={e =>
                            handleModalChangeView(e, 'Forgot password')
                        }>
                        Forgot password
                    </a>
                ) : (
                    <Link
                        to="/change-password"
                        className={styles.forgotPassword}>
                        Forgot password
                    </Link>
                )}
                {recaptchaHidden ? (
                    <p className={styles.recaptchaHidden}>
                        This site is protected by reCAPTCHA and the Google{' '}
                        <a href="https://policies.google.com/privacy">
                            Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a href="https://policies.google.com/terms">
                            Terms of Service
                        </a>{' '}
                        apply.
                    </p>
                ) : (
                    ''
                )}
            </div>
            {error ? (
                <div className={styles.errorContainer}>
                    <p className={styles.errorMessage}>
                        {error || 'Incorrect email or password! Try again.'}
                    </p>
                    <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://studentcoin.org/faq"
                        className={styles.errorLink}>
                        studentcoin.org/faq
                    </a>
                </div>
            ) : null}
            {!error && location?.state?.from === 'sign-up' ? (
                <p className={styles.fromSignUp}>
                    Your password has been changed. Provide your new credentials
                    to sign in.
                </p>
            ) : null}
            <div className={styles.formBottom}>
                <button>Sign In</button>
            </div>
        </form>
    );
};

export default Form;
