import Check from 'assets/img/check.svg';
import React, { useMemo } from 'react';

import styles from './steps.module.scss';
import { StepsProps } from './steps.types';

const setStyle = (active: boolean, done: boolean) => {
    if (done) return styles['stepsListItemDone'];
    if (active) return styles['stepsListItemActive'];
};

const setSeparator = (done: boolean) => {
    if (done) return styles['stepsListSeparatorDone'];
    return '';
};

const Steps = ({ stepNumber, steps, exclude }: StepsProps) => {
    const filteredSteps = useMemo(
        () =>
            steps.filter(step => {
                if (exclude) return !exclude.includes(step.id);
                return true;
            }),
        [steps, exclude],
    );

    return (
        <div className={styles.steps}>
            <ul
                className={styles.stepsList}
                style={{
                    width: steps.length >= 3 ? '100%' : '65%',
                }}>
                {filteredSteps.map((step, index, array) => {
                    const active = index === stepNumber;
                    const done = index < stepNumber;

                    return (
                        <React.Fragment key={index}>
                            <li className={setStyle(active, done)}>
                                <div className={styles.stepsListIndex}>
                                    {done ? (
                                        <img src={Check} alt="check" />
                                    ) : (
                                        <span
                                            className={
                                                styles.stepsListIndexSpan
                                            }>
                                            {index + 1}
                                        </span>
                                    )}
                                </div>
                                <span>{step.text}</span>
                            </li>
                            {index < array.length - 1 ? (
                                <div
                                    className={`${
                                        styles.stepsListSeparator
                                    } ${setSeparator(done)}`}>
                                    <span>••</span>
                                </div>
                            ) : null}
                        </React.Fragment>
                    );
                })}
            </ul>
        </div>
    );
};

export default Steps;
