import Input from 'components/global/input/input';
import { RedeemFormContext } from 'contexts/redeemFormContext';
import { useAcceptTermsInput } from 'hooks/useAcceptTermsInput';
import { useContext } from 'react';
import { LoadingButton } from '../LoadingButton/loadingButton';
import styles from './acceptTerms.module.scss';

export const AcceptTerms = () => {
    const { handleAcceptTerms, stageLoading } = useContext(RedeemFormContext);

    const { error, handleChange, handleSend } =
        useAcceptTermsInput(handleAcceptTerms);

    return (
        <>
            <ol className={styles.list}>
                <li>
                    I understand that I am performing STC Token redemption after
                    the first deadline.
                </li>
                <li>
                    I understand that support for my process may be limited due
                    to performing it in the second round (late redemption).
                </li>
                <li>
                    I understand that I need to provide the correct USDC
                    Ethereum ERC-20 address, and once I submit it, the USDC will
                    be sent to it irreversibly.
                </li>
                <li>
                    I understand that in some cases, I may receive a support
                    request for additional information to my associated email.
                </li>
            </ol>
            <span className={styles.label}>
                Please type &quot;I agree and confirm&quot;
            </span>
            <Input placeholder="I agree and confirm" change={handleChange} />
            {error && (
                <span className={styles.error}>
                    The input must be &quot;I agree and confirm&quot;
                </span>
            )}
            <div className={styles.btnContainer}>
                <LoadingButton
                    content="Send"
                    onClick={handleSend}
                    isLoading={stageLoading}
                />
            </div>
        </>
    );
};
