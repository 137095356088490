/* eslint-disable react/prop-types */
import DefaultErrorFallback from 'components/errors/defaultErrorFallback/defaultErrorFallback';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, Route } from 'react-router-dom';
import { api } from 'services/api/api';

const AuthRoute = ({ component: Component, ...rest }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const pathname = { ...rest }?.location?.pathname;

    const checkAuth = () => {
        const cancelablePromise = api.user.isLoggedIn();

        cancelablePromise
            .then(() => setIsLoggedIn(true))
            .catch(() => {
                localStorage.removeItem('token');
                setIsLoggedIn(false);
            })
            .then(() => setIsLoading(false));

        return cancelablePromise;
    };

    useEffect(() => {
        const cancelablePromise = checkAuth();

        return () => {
            cancelablePromise.cancel();
        };
    }, []);

    const exception = () => {
        const avaiblePaths = ['smt', 'lean'];
        const pathArray = pathname.split('/').filter((item: string) => item);

        if (
            pathArray.length === 2 &&
            (pathArray[0] === 'launchpad' || pathArray[0] === 'university') &&
            avaiblePaths.includes(pathArray[1])
        )
            return true;
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (
            pathArray.length === 1 &&
            (pathArray[0] === 'launchpad' || pathArray[0] === 'university')
        )
            return true;
        return false;
    };

    return isLoading ? null : (
        <Route
            {...rest}
            render={props =>
                isLoggedIn || exception() ? (
                    <Component {...props} isLoggedIn={isLoggedIn} />
                ) : (
                    <Redirect to={{ pathname: '/sign-in' }} />
                )
            }
        />
    );
};

const ProtectRoute = ({ component: Component, ...rest }) => {
    const token = localStorage.getItem('token');
    return token ? (
        <Redirect to={{ pathname: '/' }} />
    ) : (
        <Route
            {...rest}
            render={props => (
                <div className={rest.className}>
                    <Component {...props} />
                </div>
            )}
        />
    );
};

const ProtectLaunchpad = ({ component: Component, ...rest }) => {
    const pathname = { ...rest }?.location?.pathname;

    const avaiblePaths = ['smt', 'lean'];
    const pathArray = pathname.split('/').filter((item: string) => item);

    if (
        pathArray.length === 2 &&
        pathArray[0] === 'launchpad' &&
        avaiblePaths.includes(pathArray[1])
    )
        return (
            <ErrorBoundary fallbackRender={DefaultErrorFallback}>
                <Route {...rest} render={props => <Component {...props} />} />
            </ErrorBoundary>
        );

    return <Redirect to={{ pathname: '/' }} />;
};

export { AuthRoute, ProtectLaunchpad, ProtectRoute };
