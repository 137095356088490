import GA4React from 'ga-4-react';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import Modal from 'react-modal';
import { Redirect, Switch, useHistory } from 'react-router-dom';
import { AuthRoute, ProtectRoute } from 'utils/ProtectedRoutes';
import ResetPassword from 'views/auth/resetPassword/resetPassword';
import SignIn from 'views/auth/signIn/signIn';
import { Redeem } from 'views/redeem/redeem';

const App = () => {
    const history = useHistory();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const ga4react = new GA4React('G-61ZN3H7M2B');
        if (history) {
            ga4react.initialize().then(
                () => {
                    ga4react.pageview(history.location.pathname);
                    history.listen(location => {
                        ga4react.pageview(location.pathname);
                    });
                },
                err => {
                    console.error(err);
                },
            );
        }
    }, [history]);

    useEffect(() => {
        TagManager.initialize({
            gtmId: 'GTM-MZKTDM6',
        });
    }, []);

    useEffect(() => {
        window.onunhandledrejection = () => {
            setIsOpen(true);
        };
    }, []);

    return (
        <>
            <Switch>
                <ProtectRoute
                    path="/change-password/:token"
                    component={ResetPassword}
                />
                <ProtectRoute
                    path="/change-password"
                    component={ResetPassword}
                />
                <ProtectRoute
                    path="/sign-in"
                    component={SignIn}
                    className="protectedRoute"
                />
                <AuthRoute path="/redeem" component={Redeem} />
                <Redirect to="/redeem" />
            </Switch>
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                shouldCloseOnOverlayClick={true}
                className={`modal-body error-modal`}
                overlayClassName="modal-overlay error-modal-overlay"
                closeTimeoutMS={300}
                ariaHideApp={false}>
                <h2>Something went wrong</h2>
                <p>An unexpected error occurred</p>
                <button onClick={() => window.location.reload()}>
                    Reload the page
                </button>
            </Modal>
        </>
    );
};

export default App;
