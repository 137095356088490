import { RedeemFormContext } from 'contexts/redeemFormContext';
import { useCallback, useContext, useState } from 'react';

export const useAcceptTermsInput = (onCorrectInput: () => void) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const { handleAgreeInput } = useContext(RedeemFormContext);

    const handleChange = useCallback((text: string) => {
        setError(false);
        setValue(text);
    }, []);

    const handleSend = useCallback(() => {
        const isAgreed = handleAgreeInput(value);

        if (!isAgreed) {
            setError(true);
            return;
        }

        onCorrectInput();
    }, [onCorrectInput, value, handleAgreeInput]);

    return {
        handleChange,
        handleSend,
        error,
    };
};
