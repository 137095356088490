import { formBoxData } from 'components/redeem/formBox/formBox.data';
import { DelistContext } from 'contexts/delistContext';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { api } from 'services/api/api';
import { useKyc } from 'views/redeem/DelistContextProvider/DelistContextProvider.hooks';
import { KycStatus } from 'views/redeem/DelistContextProvider/DelistContextProvider.types';
import { initialFormChecks } from './RedeemFormContenxtProvider.data';
import {
    CheckboxName,
    CheckboxNameType,
    FormCheckType,
    FormInputsType,
    ModalIdType,
} from './RedeemFormContextProvider.types';

export const useSteps = () => {
    const {
        formStages: steps,
        setFormStageStatus,
        fetchPassedStages,
    } = useContext(DelistContext);

    const findStep = useCallback(
        (id: ModalIdType) => {
            return steps.find(step => step.modalId === id);
        },
        [steps],
    );

    const currentStep = useMemo(() => {
        return steps.find(
            step => step.status !== 'passed' && step.status !== 'waiting',
        );
    }, [steps]);

    const { fetchKyc, kycStatus, kycUrl } = useKyc();

    const { passedStages } = useContext(DelistContext);

    const getKycStatus = useCallback((kycStatus: KycStatus) => {
        switch (kycStatus) {
            case 'NEEDED':
                return 'pending';
            case 'IN_PROGRESS':
                return 'warning';
            case 'DECLINED':
                return 'error';
            default:
                return 'pending';
        }
    }, []);

    useEffect(() => {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        if (
            passedStages.includes('instruction_passed') &&
            !passedStages.includes('kyc_completed') &&
            !kycStatus
        ) {
            fetchKyc();
        }
    }, [fetchKyc, kycStatus, passedStages]);

    useEffect(() => {
        if (
            passedStages.includes('instruction_passed') &&
            !passedStages.includes('kyc_completed')
        ) {
            const statusFromKyc = getKycStatus(kycStatus);
            setFormStageStatus('complete-kyc', statusFromKyc);
        }
    }, [fetchKyc, kycStatus, getKycStatus, passedStages, setFormStageStatus]);

    useEffect(() => {
        let interval = null;

        if (kycStatus === 'IN_PROGRESS') {
            interval = setInterval(() => {
                fetchKyc(false);
                fetchPassedStages();
            }, 10000);
        } else {
            interval && clearInterval(interval);
        }

        return () => {
            interval && clearInterval(interval);
        };
    }, [fetchKyc, kycStatus, fetchPassedStages]);

    return {
        steps,
        currentStep,
        findStep,
        kycUrl,
    };
};

export const useModal = () => {
    const [modalId, setModalId] = useState<ModalIdType | null>(null);

    const handleOpenModal = useCallback((id: ModalIdType) => {
        setModalId(id);
    }, []);

    const handleCloseModal = useCallback(() => {
        setModalId(null);
    }, []);

    return {
        modalId,
        setModalId,
        handleOpenModal,
        handleCloseModal,
    };
};

export const useForm = () => {
    const [formChecks, setFormChecks] =
        useState<FormCheckType[]>(initialFormChecks);
    const [formMail, setFormMail] = useState<string[]>(['']);
    const [formTickets, setFormTickets] = useState<string[]>(['']);
    const [checkError, setCheckError] = useState<CheckboxNameType | null>(null);
    const [formError, setFormError] = useState<string | null>(null);

    const { fetchPassedStages } = useContext(DelistContext);

    const checkLocalStorageValue = useCallback((localstorageForm: any) => {
        if (!Array.isArray(localstorageForm) || !localstorageForm.length) {
            return false;
        }

        const isCorrectForm = localstorageForm.every(
            (step: any) =>
                // eslint-disable-next-line no-prototype-builtins
                step.hasOwnProperty('name') && step.hasOwnProperty('value'),
        );

        if (!isCorrectForm) return false;

        return localstorageForm.every((step: any) => {
            return Object.values(CheckboxName).includes(step.name);
        });
    }, []);

    const handleSaveFormAction = useCallback(
        (
            oldValues: string[],
            newValue: string,
            action: 'add' | 'remove' | 'replace',
        ) => {
            oldValues = oldValues || [];

            switch (action) {
                case 'add':
                    return [...oldValues, newValue];
                case 'remove':
                    return oldValues.filter(v => v !== newValue);
                case 'replace':
                    return [newValue];
            }
        },
        [],
    );

    const saveForm = useCallback(
        (
            name: CheckboxNameType,
            value: string | null,
            action: 'add' | 'remove' | 'replace',
        ) => {
            setFormError(null);
            if (name === checkError) {
                setCheckError(null);
            }

            const updatedFormChecks = formChecks.map(step => {
                if (step.name === name) {
                    return {
                        ...step,
                        value: handleSaveFormAction(step.value, value, action),
                    };
                }
                return step;
            });

            setFormChecks(updatedFormChecks);

            localStorage.setItem(
                'redeemForm',
                JSON.stringify(updatedFormChecks),
            );
        },
        [formChecks, handleSaveFormAction, checkError],
    );

    const saveInputForm = useCallback((name: string, value: string[]) => {
        const array = value.filter(v => v !== '');

        localStorage.setItem(name, JSON.stringify(array));
    }, []);

    const isMailCorrect = useCallback((mail: string) => {
        const mailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        return mail === '' || mailRegex.test(mail);
    }, []);

    const isTicketCorrect = useCallback((ticket: string) => {
        const ticketRegex = /^#?\d{7}$/;

        return ticket === '' || ticketRegex.test(ticket);
    }, []);

    const handleFormMail = useCallback(
        (mail: string[]) => {
            setFormMail(mail);

            const formattedMails = mail.filter(mail => !!mail);
            const isCorrect = formattedMails.every(isMailCorrect);

            if (isCorrect) {
                saveInputForm('formMail', formattedMails);
            }

            return isCorrect;
        },
        [isMailCorrect, saveInputForm],
    );

    const handleFormTickets = useCallback(
        (tickets: string[]) => {
            setFormTickets(tickets);
            saveInputForm('formTickets', tickets);
            return true;
        },
        [saveInputForm],
    );

    const formInputs = useMemo<FormInputsType[]>(() => {
        return [
            {
                id: 'mail' as const,
                changeFn: handleFormMail,
                checkFn: isMailCorrect,
                errorMsg: 'Invalid email',
            },
            {
                id: 'tickets' as const,
                changeFn: handleFormTickets,
                checkFn: isTicketCorrect,
                errorMsg: 'Invalid tickets',
            },
        ];
    }, [handleFormMail, handleFormTickets, isMailCorrect, isTicketCorrect]);

    useEffect(() => {
        const localstorageForm = JSON.parse(
            localStorage.getItem('redeemForm') || '[]',
        );

        if (localstorageForm && checkLocalStorageValue(localstorageForm)) {
            setFormChecks(localstorageForm);
        }
    }, [checkLocalStorageValue]);

    useEffect(() => {
        const localstorageMail = localStorage.getItem('formMail');
        const localstorageTickets = localStorage.getItem('formTickets');

        const parsedMails = localstorageMail
            ? JSON.parse(localstorageMail)
            : [''];
        const parsedTickets = localstorageTickets
            ? JSON.parse(localstorageTickets)
            : [''];

        const isEveryMailCorrect = parsedMails.every(isMailCorrect);

        if (parsedMails && isEveryMailCorrect) {
            setFormMail(parsedMails);
        }
        if (parsedTickets) {
            setFormTickets(parsedTickets);
        }
    }, [isMailCorrect]);

    const handleAgreeInput = useCallback(
        (text: string) => text.trim() === 'I agree and confirm',
        [],
    );

    const handleFormSend = useCallback(() => {
        const emptyCheck = formChecks.find(
            check => check.value === null || check.value.length === 0,
        );

        if (emptyCheck) {
            setCheckError(emptyCheck.name);
            const element = document.getElementById(emptyCheck.name);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            return;
        }

        const formattedChecks = formChecks.map(check => {
            const item = formBoxData.find(item => item.name === check.name);

            const values = item.checkboxes.filter(checkbox => {
                return check.value.includes(checkbox.id);
            });

            const formattedValues = values.map(value => {
                return value.sendValue || value.label;
            });

            return {
                [check.name]:
                    item.type === 'checkbox'
                        ? formattedValues
                        : formattedValues[0],
            };
        });

        if (formTickets.length > 0 && formTickets[0]) {
            formattedChecks.push({
                supportTicket: formTickets[0],
            });
        }

        const checksObject = Object.assign({}, ...formattedChecks);

        api.delist
            .form({
                form: checksObject,
            })
            .then(() => {
                fetchPassedStages();
            })
            .catch(e => {
                setFormError(e.body.message);
            });
    }, [formChecks, fetchPassedStages, formTickets]);

    return {
        formChecks,
        formMail,
        formTickets,
        formInputs,
        saveForm,
        handleAgreeInput,
        handleFormSend,
        checkError,
        formError,
    };
};
