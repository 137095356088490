import { formBoxData } from 'components/redeem/formBox/formBox.data';
import { FormButtonType } from 'components/redeem/formSteps/formSteps.types';
import { FormCheckType } from './RedeemFormContextProvider.types';

export const initialSteps: FormButtonType[] = [
    {
        modalId: 'kyc-instruction',
        label: 'Please watch the KYC instruction manual',
        status: 'waiting',
        completedId: 'instruction_passed',
    },
    {
        modalId: 'complete-kyc',
        label: 'Complete KYC',
        status: 'waiting',
        clickStatuses: ['pending', 'error', 'warning'],
        completedId: 'kyc_completed',
    },
    {
        modalId: 'download-tor',
        label: 'Download, Read & Sign ToR',
        status: 'waiting',
        clickStatuses: ['pending', 'passed'],
        completedId: 'tor_downloaded',
    },
    {
        modalId: 'upload-tor',
        label: 'Upload ToR',
        status: 'waiting',
        completedId: 'tor_uploaded',
    },
    {
        modalId: 'accept-terms',
        label: 'Accept terms',
        status: 'waiting',
        completedId: 'terms_accepted',
    },
    {
        modalId: 'accept-late-withdrawal',
        label: 'Acknowledge redemption after deadline',
        status: 'waiting',
        completedId: 'redemption_acknowledge_accepted',
    },
    {
        modalId: 'accept-announcement',
        label: 'Accept announcement',
        status: 'waiting',
        completedId: 'announcement_accepted',
    },
];

export const initialFormChecks: FormCheckType[] = formBoxData.map(item => {
    return {
        name: item.name,
        value: null,
    };
});
