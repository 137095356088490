/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable react/display-name */
import Logo from 'assets/img/full-logo.svg';
import { ReactNode, useMemo, useState } from 'react';
import Slider from 'react-slick';
import { ReactSVG } from 'react-svg';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Dot, Dots } from './dots/dots';
import { items } from './overlay.data';
import styles from './overlay.module.scss';
import { OverlayProps } from './overlay.types';
import Slide from './slide/slide';

const Overlay = ({ children }: OverlayProps) => {
    const [slideIndex, setSlideIndex] = useState(0);

    const settings = useMemo(() => {
        return {
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 600,
            autoplaySpeed: 6000,
            autoplay: true,
            arrows: false,
            dots: true,
            beforeChange: (_, next) => {
                setSlideIndex(next);
            },
            appendDots: (dots: ReactNode) => <Dots dots={dots} />,
            customPaging: (index: number) => (
                <Dot index={index} slideIndex={slideIndex} />
            ),
        };
    }, [slideIndex]);

    return (
        <div className={styles.overlay}>
            <div className={styles.carousel}>
                <Slider {...settings}>
                    {items.map((item, key) => {
                        return (
                            <Slide
                                header={item.header}
                                stc={item.stc}
                                image={item.image}
                                text={item.text}
                                key={key}
                            />
                        );
                    })}
                </Slider>
            </div>
            <div className={styles.content}>
                <ReactSVG src={Logo} className={styles.logo} />
                {children}
            </div>
        </div>
    );
};

export default Overlay;
