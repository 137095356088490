import { ReactSVG } from 'react-svg';
import styles from './BlockModal.module.scss';
import { BlockModalProps } from './BlockModal.types';

export const BlockModal = ({ icon, text }: BlockModalProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.icon}>
                <ReactSVG src={icon} />
            </div>
            <p className={styles.text}>{text}</p>
        </div>
    );
};
