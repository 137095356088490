import InfoIcon from 'assets/img/info.svg';
import styles from './redeemTooltip.module.scss';
import { RedeemTooltipProps } from './redeemTooltip.types';

export const RedeemTooltip = ({ tooltip }: RedeemTooltipProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.tooltipContainer}>
                <img src={InfoIcon} alt="info" />
                <div className={styles.tooltip}>{tooltip}</div>
            </div>
        </div>
    );
};
