import BigNumber from 'bignumber.js';
import { useCallback } from 'react';

export const useValidate = (
    address: string,
    amount: BigNumber,
    walletBalance: BigNumber,
    feeValue: number,
    check: boolean[],
) => {
    const validateAddress = useCallback(
        (errors: unknown) => {
            if (address.trim() === '') {
                errors['address'] = "Address can't be empty";
            } else if (address.length !== 42 || !address.startsWith('0x')) {
                errors['address'] = 'Address is wrong';
            } else if (
                address.toLowerCase().trim() ===
                    '0x036d8e64b2cd9e8182e74f221f98e4b6ed29e433' ||
                address.toLowerCase().trim() ===
                    '0xb6cfc5315e9d03ca1b9e9d334db87a7533433851' ||
                address.toLowerCase().trim() ===
                    '0x9c628f8da82a4f84dc5a0407547730271ad482a8' ||
                address.toLowerCase().trim() ===
                    '0x94f601083c4dfee92ea201714977bae44b4a31f8' ||
                address.toLowerCase().trim() ===
                    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
            ) {
                errors['address'] = 'Address is wrong';
            }
        },
        [address],
    );

    const validateAmount = useCallback(
        (errors: unknown) => {
            if (amount.isNaN()) {
                errors['amount'] = 'Amount have to be a number';
            } else if (amount.lte(0)) {
                errors['amount'] = 'Provide correct amount';
            } else if (walletBalance.lt(amount)) {
                errors['amount'] = 'Not enough balance';
            } else if (amount.toNumber() - feeValue <= 0) {
                errors['amount'] = 'Fee is greater than amount';
            }
        },
        [amount, feeValue, walletBalance],
    );

    const validateCheck = useCallback(
        errors => {
            if (!check) {
                errors['check'] = true;
            }

            console.log(check);

            check.forEach((item, index) => {
                if (!item) {
                    errors[`check${index}`] = true;
                }
            });
        },
        [check],
    );

    const validateAll = useCallback(() => {
        const errors = {};

        validateAddress(errors);
        validateAmount(errors);
        validateCheck(errors);

        if (Object.keys(errors).length !== 0) {
            throw errors;
        }
    }, [validateAddress, validateAmount, validateCheck]);

    return { validateAll };
};
