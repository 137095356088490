import { RedeemFormContext } from 'contexts/redeemFormContext';
import { useCallback, useContext, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { LoadingButton } from '../LoadingButton/loadingButton';
import { DropContent } from './DropContent/DropContent';
import styles from './uploadTor.module.scss';

export const UploadTor = () => {
    const { handleUploadTor, stageLoading, torUploadError, setTorUploadError } =
        useContext(RedeemFormContext);
    const [file, setFile] = useState<File | null>(null);
    const [hover, setHover] = useState(false);
    const [fileError, setFileError] = useState(false);

    const handleChange = useCallback(
        file => {
            setFileError(false);
            setTorUploadError(false);
            setFile(file);
        },
        [setTorUploadError],
    );

    const handleDrag = useCallback(isHovering => {
        setHover(isHovering);
    }, []);

    const handleSend = useCallback(() => {
        if (!file) {
            setFileError(true);
            return;
        }

        handleUploadTor(file);
    }, [file, handleUploadTor]);

    return (
        <>
            <p>Please upload signed Terms of Late Redemption</p>
            <div className={styles.buttonContainer}>
                <FileUploader
                    types={['pdf']}
                    handleChange={handleChange}
                    dropMessageStyle={{ display: 'none' }}
                    maxSize={10}
                    onSizeError={() => setFileError(true)}
                    onDraggingStateChange={handleDrag}>
                    <div className={styles.uploadContainer}>
                        <DropContent hover={hover} file={file} />
                    </div>
                </FileUploader>
                {!file && fileError && (
                    <p className={styles.error}>
                        Please upload a file (max 10 MB)
                    </p>
                )}
                {file && fileError && (
                    <p className={styles.warning}>
                        File must be in PDF format and max 10 MB
                    </p>
                )}
                {torUploadError && (
                    <p className={styles.error}>
                        Network error. File is probably too large.
                    </p>
                )}
            </div>
            <div className={styles.sendBtnContainer}>
                <LoadingButton
                    content="Send"
                    isLoading={stageLoading}
                    onClick={handleSend}
                />
            </div>
        </>
    );
};
