import styles from './dots.module.scss';
import { DotProps, DotsProps } from './dots.types';

const Dot = ({ index, slideIndex, onClick }: DotProps) => {
    return (
        <div
            onClick={onClick}
            className={`${styles.dot} ${
                slideIndex === index ? styles.dotActive : ''
            }`}></div>
    );
};

const Dots = ({ dots }: DotsProps) => {
    return <ul className={`slick-dots ${styles.dots}`}>{dots}</ul>;
};

export { Dot, Dots };
