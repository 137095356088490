import { ReactNode } from 'react';

export type ModalIdType =
    | 'kyc-instruction'
    | 'complete-kyc'
    | 'download-tor'
    | 'upload-tor'
    | 'accept-terms'
    | 'accept-late-withdrawal'
    | 'accept-announcement';

export type RedeemFormContextProps = {
    children: ReactNode;
};

export const CheckboxName = {
    MULTIPLE_ACCOUNTS: 'multipleAccounts',
    LAUNCHPAD: 'launchpad',
    BONUS_TOKENS: 'bonusTokens',
    AFFILIATE: 'affiliate',
    BONUS_ETH: 'bonusEth',
    BONUS_STC: 'bonusStc',
    AIRDROP: 'airdrop',
    EXTERNAL_SOURCE: 'externalSource',
    PREMIUM_HOLDER: 'premiumHolder',
    DAO_VOTING: 'daoVoting',
    CONVERTER: 'converter',
    STC_TOKENS: 'stcTokens',
    SMT_LAUNCHPAD: 'smtLaunchpad',
    SMT_AIRDROP: 'smtAirdrop',
    LEAN_LAUNCHPAD: 'leanLaunchpad',
    LEAN_AIRDROP: 'leanAirdrop',
    TOKENS: 'tokens',
    SUPPORT: 'support',
} as const;

export type CheckboxNameType = (typeof CheckboxName)[keyof typeof CheckboxName];

export type FormCheckType = {
    name: CheckboxNameType;
    value: string[] | null;
};

export type FormInputsType = {
    id: 'mail' | 'tickets';
    changeFn: (value: string[]) => boolean;
    checkFn?: (value: string) => boolean;
    errorMsg: string;
};
