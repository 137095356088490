/* eslint-disable @typescript-eslint/no-var-requires */

const stc = 'Student Coin Wallet';

export const items = [
    {
        stc,
        image: (
            <img
                src={require(`assets/img/auth/slide1.svg`).default}
                alt="wallet"
                style={{ marginBottom: '50px' }}
            />
        ),
        header: (
            <span>
                <span className="special">Student Coin</span> educates and
                empowers individuals in the blockchain industry
            </span>
        ),
        text: 'Through premium courses at STC.University and free beginner resources at STC Academy, fostering knowledge and innovation.',
    },
    {
        stc,
        image: (
            <img
                src={require(`assets/img/auth/slide2.svg`).default}
                alt="wallet"
                style={{ marginTop: '25px', marginBottom: '30px' }}
            />
        ),
        header: (
            <span>
                Influence project <br /> development by
                <span className="special"> voting</span>
            </span>
        ),
        text: 'STC holders are invited to influence strategic decisions made at Student Coin through voting in polls.',
    },
    {
        stc,
        image: (
            <img
                src={require(`assets/img/auth/slide4.svg`).default}
                alt="wallet"
                style={{ marginTop: '25px', marginBottom: '70px' }}
            />
        ),
        header: (
            <span>
                Join the growing <br />
                <span className="special"> STC Ecosystem</span>
            </span>
        ),
        text: 'Student Coin community consists of over 300,000 users worldwide! Sign up and be a part of it!',
    },
];
