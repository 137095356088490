export const formatDate = (
    date: string | number,
    region: string,
    dateStyle: 'full' | 'long' | 'medium' | 'short',
    hour12 = true,
) => {
    const formatDate: Date = new Date(date);

    return (
        formatDate.toLocaleDateString([region], { dateStyle: dateStyle }) +
        ' ' +
        formatDate.toLocaleTimeString([], { timeStyle: 'short', hour12 })
    );
};
