import {
    CheckboxNameType,
    FormCheckType,
    FormInputsType,
    ModalIdType,
} from 'components/redeem/content/RedeemFormContextProvider/RedeemFormContextProvider.types';
import { FormButtonType } from 'components/redeem/formSteps/formSteps.types';
import { createContext } from 'react';

export type RedeemFormContextTypes = {
    steps: FormButtonType[];
    currentStep: FormButtonType;
    handleOpenModal: (id: ModalIdType) => void;
    handleKycApprove: () => void;
    handleDownloadTor: () => void;
    handleUploadTor: (file: File) => void;
    handleAgreeInput: (text: string) => boolean;
    handleAcceptTerms: () => void;
    handleAcceptLateWithdrawal: () => void;
    handleAcceptAnnouncement: () => void;
    modalId: ModalIdType | null;
    handleCloseModal: () => void;
    setModalId: (value: ModalIdType | null) => void;
    handleKycInstructionApprove: (value: boolean) => void;
    formChecks: FormCheckType[];
    saveForm: (
        name: CheckboxNameType,
        value: string | null,
        action: 'add' | 'remove' | 'replace',
    ) => void;
    formMail: string[];
    formTickets: string[];
    formInputs: FormInputsType[];
    handleFormSend: () => void;
    checkError: CheckboxNameType | null;
    formError: string | null;
    stageLoading: boolean;
    torUploadError: boolean;
    setTorUploadError: (value: boolean) => void;
};

export const RedeemFormContext = createContext<Partial<RedeemFormContextTypes>>(
    {},
);
