import { createContext } from 'react';
import {
    ActiveWalletType,
    AuthStepsType,
    KycType,
    StakingType,
    TokensType,
    TransactionType,
    UserType,
    UtmsType,
    WalletsType,
} from 'views/overlay/overlay.types';

type AppContextTypes = {
    isLoggedIn: {
        isLoggedIn: boolean;
        setIsLoggedIn: (isLoggedIn: boolean) => void;
    };
    authModal: {
        showAuthModal: boolean;
        setShowAuthModal: (showAuthModal: boolean) => void;
        authModalStep: AuthStepsType;
        setAuthModalStep: (authModalStep: AuthStepsType) => void;
    };
    user: {
        user: UserType;
        setUser: (user: UserType) => void;
        userLoading: boolean;
    };
    transactions: {
        transactions: TransactionType[];
        setTransactions: (transactions: TransactionType[]) => void;
        transactionsLoading: boolean;
        fetchTransactions: () => void;
    };
    staking: {
        stakingHistory: StakingType[];
        setStakingHistory: (staking: StakingType[]) => void;
        stakingLoading: boolean;
        fetchStaking: () => void;
    };
    wallets: {
        wallets: WalletsType[];
        setWallets: (wallets: WalletsType[]) => void;
        userLoading: boolean;
        fetchWallets: () => void;
    };
    activeWallet: {
        activeWallet: ActiveWalletType;
        setActiveWallet: (wallet: ActiveWalletType) => void;
    };
    width: number;
    tokens: {
        tokens: TokensType;
        tokensLoading: boolean;
    };
    kyc: {
        kyc: KycType;
        kycShow: boolean;
        setKycShow: (show: boolean) => void;
    };
    coinbase: () => void;
    utms: UtmsType;
    swapModal: {
        showSwapModal: boolean;
        setShowSwapModal: (showSwapModal: boolean) => void;
    };
};

export const AppContext = createContext<Partial<AppContextTypes>>({});
