import Checkbox from 'components/global/checkbox/checkbox';
import { RedeemFormContext } from 'contexts/redeemFormContext';
import { useCallback, useContext, useState } from 'react';
import { LoadingButton } from '../LoadingButton/loadingButton';
import styles from './kycInstruction.module.scss';

export const KycInstruction = () => {
    const { handleKycInstructionApprove, stageLoading } =
        useContext(RedeemFormContext);
    const [checked, setChecked] = useState(false);
    const [checkError, setCheckError] = useState(false);

    const handleCheck = useCallback(() => {
        setCheckError(false);
        setChecked(prev => !prev);
    }, []);

    const handleBtnClick = useCallback(() => {
        if (!checked) {
            setCheckError(true);
            return;
        }

        handleKycInstructionApprove(true);
    }, [checked, handleKycInstructionApprove]);

    return (
        <>
            <p>
                Please watch the instruction on how to complete the KYC
                procedure.
            </p>
            <a
                href="https://youtu.be/gbIrhol4ES0"
                target="_blank"
                rel="noreferrer">
                https://youtu.be/gbIrhol4ES0
            </a>
            <div className={styles.container}>
                <Checkbox
                    message="I have watched the instruction"
                    check={checked}
                    setCheck={handleCheck}
                    checkError={checkError}
                />
                <div className={styles.btnContainer}>
                    <LoadingButton
                        content="Complete"
                        isLoading={stageLoading}
                        onClick={handleBtnClick}
                    />
                </div>
            </div>
        </>
    );
};
