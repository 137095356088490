import { RedeemFormContext } from 'contexts/redeemFormContext';
import {
    ChangeEvent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import styles from './checkbox.module.scss';
import { CheckboxProps } from './checkbox.types';
import { CheckboxInput } from './checkboxInput/checkboxInput';

export const Checkbox = ({
    text,
    type = 'radio',
    name,
    checkboxes,
    checkError,
}: CheckboxProps) => {
    const [inputErrorMsg, setInputErrorMsg] = useState<string | null>(null);
    const [showErrorMsg, setShowErrorMsg] = useState<boolean[]>([false]);
    const [inputCounter, setInputCounter] = useState(1);
    const { saveForm, formChecks, formInputs, formMail, formTickets } =
        useContext(RedeemFormContext);

    useEffect(() => {
        const mailLength = formMail.length || 1;
        const ticketsLength = formTickets.length || 1;

        setInputCounter(
            name === 'multipleAccounts' ? mailLength : ticketsLength,
        );
    }, [formMail.length, formTickets.length, name]);

    const formCheck = useMemo(() => {
        return formChecks.find(formCheck => formCheck.name === name);
    }, [formChecks, name]);

    const selectCorrectAction = useCallback(
        (checked: boolean) => {
            if (type === 'radio') return 'replace';

            return checked ? 'add' : 'remove';
        },
        [type],
    );

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const action = selectCorrectAction(e.target.checked);
            saveForm(name, e.target.id, action);
        },
        [name, saveForm, selectCorrectAction],
    );

    const handleInputChange = useCallback(
        (
            e: ChangeEvent<HTMLInputElement>,
            id: 'mail' | 'tickets',
            index: number,
        ) => {
            setShowErrorMsg(prev => {
                const newShowErrorMsg = [...prev];
                newShowErrorMsg[index] = false;

                return newShowErrorMsg;
            });
            const input = formInputs.find(input => input.id === id);

            if (!input) return;

            const inputs = [...formMail];

            inputs[index] = e.target.value;

            input.changeFn(inputs);
        },
        [formInputs, formMail],
    );

    const handleBlur = useCallback(
        (index: number, id: 'mail' | 'tickets') => {
            const input = formInputs.find(input => input.id === id);

            const value = id === 'mail' ? formMail[index] : formTickets[index];

            const isCorrect = input?.checkFn(value || '');

            if (!isCorrect) {
                setInputErrorMsg(input.errorMsg);
                setShowErrorMsg(prev => {
                    const newShowErrorMsg = [...prev];
                    newShowErrorMsg[index] = true;

                    return newShowErrorMsg;
                });
            }
        },
        [formInputs, formMail, formTickets],
    );

    const handleBtnClick = useCallback(() => {
        setInputCounter(prev => prev + 1);
        setShowErrorMsg(prev => [...prev, false]);
    }, []);

    return (
        <div className={styles.container} id={name}>
            {text && <span className={styles.title}>{text}</span>}
            <div>
                {checkboxes.map(checkbox => (
                    <div key={checkbox.id}>
                        <div className={styles.checkboxContainer}>
                            <input
                                type={type}
                                name={name}
                                id={checkbox.id}
                                onChange={handleChange}
                                checked={formCheck.value?.includes(checkbox.id)}
                            />
                            <label
                                htmlFor={checkbox.id}
                                className={styles.label}>
                                {checkbox.label}
                            </label>
                        </div>
                        {checkbox.inputPlaceholder &&
                            formCheck.value?.includes(checkbox.id) && (
                                <div className={styles.inputContainer}>
                                    {Array.from({
                                        length: inputCounter,
                                    }).map((_, index) => (
                                        <CheckboxInput
                                            key={`${checkbox.inputId}_${index}`}
                                            errorMsg={inputErrorMsg}
                                            handleBlur={() =>
                                                handleBlur(
                                                    index,
                                                    checkbox.inputId,
                                                )
                                            }
                                            handleInputChange={
                                                handleInputChange
                                            }
                                            inputId={checkbox.inputId}
                                            inputPlaceholder={
                                                checkbox.inputPlaceholder
                                            }
                                            showErrorMsg={showErrorMsg[index]}
                                            value={
                                                checkbox.inputId === 'mail'
                                                    ? formMail[index]
                                                    : formTickets[index]
                                            }
                                            index={index}
                                        />
                                    ))}
                                    {inputCounter < checkbox.maxInputCount && (
                                        <button
                                            className={styles.moreButton}
                                            onClick={handleBtnClick}>
                                            +
                                        </button>
                                    )}
                                </div>
                            )}
                    </div>
                ))}
                {checkError === name && (
                    <span className={styles.error}>
                        You need to fill this point.
                    </span>
                )}
            </div>
        </div>
    );
};
