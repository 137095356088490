import AlertTriangle from 'assets/img/alert-triangle.svg';
import Info from 'assets/img/info.svg';
import { useMemo } from 'react';
import styles from './summary.module.scss';
import { SummaryProps } from './summary.types';

const Summary = ({ address, fee }: SummaryProps) => {
    const warningData = useMemo(() => {
        return undefined;
    }, []);

    return (
        <div className={styles.summary}>
            <div className={styles.summaryGeneral}>
                <div className={styles.network}>
                    <div className={styles.networkDescriptions}>
                        <p>Network</p>
                        <p>Ethereum</p>
                    </div>
                    <div className={styles.networkFee}>
                        <p>Network fee</p>
                        <p>
                            {fee.value} USDC{' '}
                            <span>≈ {fee.usd.toFixed(4)} USD</span>
                        </p>
                    </div>
                </div>
                <div className={styles.address}>
                    <p>recipient address</p>
                    <p>{address}</p>
                </div>
            </div>
            <div className={styles.note}>
                <p className={styles.noteHeader}>
                    <img src={AlertTriangle} alt="alert" />
                    <span>Warning</span>
                </p>
                {warningData ? (
                    <>
                        <p className={styles.noteText}>
                            Please ensure that you send the funds to the correct
                            address; otherwise, they may be lost forever.
                        </p>
                        <p className={styles.noteMore}>
                            Please note that {warningData.token} is not listed
                            on Binance, Coinbase, or Crypto.com.
                        </p>
                    </>
                ) : (
                    <p className={styles.noteText}>
                        Make sure you send the funds to the correct address,
                        otherwise they may be lost forever.
                    </p>
                )}
            </div>
            <div className={`${styles.note} ${styles.noteGray}`}>
                <p className={styles.noteHeader}>
                    <img src={Info} alt="info" />
                    <span>Network fee</span>
                </p>
                <p className={styles.noteText}>
                    Fees may be higher due to increased load on the Ethereum
                    network.
                </p>
            </div>
        </div>
    );
};

export default Summary;
