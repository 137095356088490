import { RedeemContent } from 'components/redeem/content/redeemContent';
import { ReducedNavbar } from 'components/reducedNavbar/reducedNavbar';
import { useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import { DelistContextProvider } from './DelistContextProvider/DelistContextProvider';
import styles from './redeem.module.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const Redeem = () => {
    useEffect(() => {
        document.title = 'Student Coin - Redeem';
    });
    return (
        <div>
            <ReducedNavbar />
            <div className={styles.content}>
                <DelistContextProvider>
                    <RedeemContent />
                </DelistContextProvider>
            </div>
        </div>
    );
};
