import Spinner from 'assets/img/auth/spinner.svg';
import { DelistContext } from 'contexts/delistContext';
import { useContext } from 'react';
import { RedeemBox } from '../box/redeemBox';
import { BoxLessValue } from '../boxLessValue/boxLessValue';
import { BoxSuccessRedeem } from '../boxSuccessRedeem/boxSuccessRedeem';
import { Error } from '../error/error';
import { FormBox } from '../formBox/formBox';
import { RedeemTemplate } from '../template/redeemTemplate';
import { RedeemFormContextProvider } from './RedeemFormContextProvider/RedeemFormContextProvider';
import { requiredStages } from './redeemContent.data';
import styles from './redeemContent.module.scss';

export const RedeemContent = () => {
    const { passedStages, showError, isStageFetched, showStcLow } =
        useContext(DelistContext);

    const isWithdrawPossible = requiredStages.every(stage =>
        passedStages.includes(stage),
    );

    if (isStageFetched) {
        return (
            <RedeemTemplate>
                <div className={styles.dataLoading}>
                    <img src={Spinner} alt="spinner" />
                </div>
            </RedeemTemplate>
        );
    }

    if (
        passedStages.includes('withdrawal_completed') ||
        passedStages.includes('withdrawal_started') ||
        passedStages.includes('kucoin_id_passed')
    ) {
        return <BoxSuccessRedeem />;
    }

    if (showError) {
        return <Error />;
    }

    if (showStcLow) {
        return <BoxLessValue />;
    }

    if (!isWithdrawPossible) {
        return (
            <RedeemFormContextProvider>
                <FormBox />
            </RedeemFormContextProvider>
        );
    }

    return <RedeemBox />;
};
