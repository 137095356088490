import Spinner from 'assets/img/auth/spinner.svg';
import PinCode from 'components/global/pinCode/pinCode';
import Resend from 'components/global/resend/resend';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from 'services/api/api';
import { makeCancelablePromise } from 'utils/cancelablePromise';
import styles from './auth.module.scss';
import { AuthProps } from './auth.types';

const Auth = ({ newWithdraw, isLoading, email }: AuthProps) => {
    const [time, setTime] = useState(0);

    const [message, setMessage] = useState('');

    const resend = useCallback(() => {
        const cancelablePromise = makeCancelablePromise(api.delist.code());

        setMessage('');

        cancelablePromise
            .then(() => {
                setTime(120);
            })
            .catch(error => {
                setMessage(error.error.body.message);
                setTime(parseInt(error.error.body.canResendNewCodeIn));
            });

        return cancelablePromise;
    }, []);

    useEffect(() => {
        const cancelablePromise = resend();

        return () => {
            cancelablePromise.cancel();
        };
    }, [resend]);

    const handleOnReady = useCallback(
        (code: string) => {
            newWithdraw(code);
        },
        [newWithdraw],
    );

    const formattedEmail = useMemo(() => {
        let value = email;
        const splitted = value.split('@');
        value = `${splitted[0][0]}***@${splitted[1]}`;

        return value;
    }, [email]);

    return (
        <div className={styles.auth}>
            <div>
                <p className={styles.authHeader}>
                    2 Factor Authentication code has been sent to&nbsp;
                    {formattedEmail}
                </p>
                <PinCode length={6} onReady={handleOnReady} />
                {isLoading && (
                    <img
                        src={Spinner}
                        className={styles.loading}
                        alt="spiner"
                    />
                )}
                <p className={styles.errorMessage}>{message}</p>
            </div>
            <Resend resendFunction={resend} time={time} setTime={setTime} />
        </div>
    );
};

export default Auth;
