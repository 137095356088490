import Input from 'components/global/input/input';
import { RedeemFormContext } from 'contexts/redeemFormContext';
import { useAcceptTermsInput } from 'hooks/useAcceptTermsInput';
import { useContext } from 'react';
import { LoadingButton } from '../LoadingButton/loadingButton';
import styles from './acceptLateWithdrawal.module.scss';

export const AcceptLateWithdrawal = () => {
    const { handleAcceptLateWithdrawal, stageLoading } =
        useContext(RedeemFormContext);

    const { error, handleChange, handleSend } = useAcceptTermsInput(
        handleAcceptLateWithdrawal,
    );

    return (
        <>
            <p>
                Please acknowledge that you are withdrawing USDC after the first
                deadline on June 9, 2024. Late withdrawals are processed once
                per month, on the first Wednesday of each month. We may contact
                you if we require more information to process your withdrawal.
                The withdrawal may take up to 3 months.
            </p>
            <span className={styles.label}>
                Please type &quot;I agree and confirm&quot;
            </span>
            <Input placeholder="I agree and confirm" change={handleChange} />
            {error && (
                <span className={styles.error}>
                    The input must be &quot;I agree and confirm&quot;
                </span>
            )}
            <div className={styles.btnContainer}>
                <LoadingButton
                    content="Send"
                    onClick={handleSend}
                    isLoading={stageLoading}
                />
            </div>
        </>
    );
};
