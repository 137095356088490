import Button from 'components/global/button/button';
import { useLogout } from 'hooks/useLogout';
import { RedeemTemplate } from '../template/redeemTemplate';
import styles from './boxSuccessRedeem.module.scss';

export const BoxSuccessRedeem = () => {
    const { logout } = useLogout();

    return (
        <RedeemTemplate
            customFooter={
                <>
                    <span>
                        <a
                            href="https://studentcoin.org"
                            target="_blank"
                            rel="noreferrer">
                            Token Reedemption Announcement
                        </a>
                    </span>
                    <span>
                        If you have any questions or need an assistance, please
                        visit our{' '}
                        <a
                            href="https://studentcoin.org/faq"
                            target="_blank"
                            rel="noreferrer">
                            FAQ
                        </a>
                        .
                    </span>
                </>
            }>
            <p className={styles.heading}>
                <strong>Thank you for accessing your STC Wallet.</strong>
            </p>
            <p>
                <strong>
                    Your redemption request is processed successfully, and USDC
                    will be transferred to your wallet. Transferring funds my
                    take up to 7 days, depending on the amount.
                </strong>
            </p>
            {/* <span className={styles.hash}>
                0xc6bfbfefe464588df72d1788fe7a13bb11c0b76fe7813f5896e98abd2430bc2a
            </span> */}
            <p>
                There are no remaining holdings in your STC Wallet. Your account
                will be terminated, and all user data erased in 6 months. Thanks
                for your involvement, and best wishes for the future.
            </p>
            <p>
                <strong>No further action is needed.</strong>
            </p>
            <div className={styles.btnContainer}>
                <Button
                    text="Sign out"
                    className={styles.logoutBtn}
                    onClick={logout}
                />
            </div>
        </RedeemTemplate>
    );
};
