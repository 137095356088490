/* eslint-disable react/prop-types */
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App.tsx';

import './assets/styles/globals.scss';
import { setup } from './services/api/api.ts';

axios.defaults.baseURL = process.env.REACT_APP_REST_SERVER;

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
    'token',
)}`;

setup();

axiosRetry(axios, {
    retries: 3,
    retryCondition: error => {
        return (
            error?.response?.config?.method === 'get' &&
            error?.response?.status >= 500
        );
    },
    retryDelay: retryCount => {
        return retryCount * 2000;
    },
});

const ErrorFallback = () => {
    return (
        <div role="alert" className="errorScreen">
            <h2>Something went wrong</h2>
            <pre>An unexpected error occurred</pre>
            <button onClick={() => window.location.reload()}>Try again</button>
        </div>
    );
};

ReactDOM.render(
    <StrictMode>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Router>
                <App />
            </Router>
        </ErrorBoundary>
    </StrictMode>,
    document.getElementById('root'),
);
