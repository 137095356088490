import SpinnerIcon from 'assets/img/auth/spinner.svg';
import Button from 'components/global/button/button';
import styles from './loadingButton.module.scss';
import { LoadingButtonProps } from './loadingButton.types';

export const LoadingButton = ({
    content,
    isLoading,
    onClick,
}: LoadingButtonProps) => {
    return (
        <Button
            text={isLoading ? <img src={SpinnerIcon} alt="spinner" /> : content}
            type="primary"
            onClick={onClick}
            className={styles.button}
        />
    );
};
