import FullLogo from 'assets/img/full-logo.svg';
import { RedeemFooter } from '../footer/redeemFooter';
import styles from './redeemTemplate.module.scss';
import { RedeemTemplateProps } from './redeemTemplate.types';

export const RedeemTemplate = ({
    children,
    customFooter,
}: RedeemTemplateProps) => {
    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <img src={FullLogo} alt="logo" />
            </div>
            {children}
            <RedeemFooter customFooter={customFooter} />
        </div>
    );
};
