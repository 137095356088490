import { MouseEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { api } from 'services/api/api';

export const useLogout = () => {
    const history = useHistory();

    const logout = (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        e.preventDefault();

        api.signOut()
            .then(() => {
                localStorage.removeItem('token');
            })
            .then(() => {
                history.push({ pathname: '/sign-in' });
            })
            .catch(error => {
                console.error(error);
            });
    };

    return { logout };
};
