import { useCallback, useContext, useState } from 'react';
import QrReader from 'react-qr-reader';
import { AppContext } from 'utils/context';

import Input from 'components/global/input/input';

import Scanner from 'assets/img/scanner.svg';

import Checkbox from 'components/global/checkbox/checkbox';
import styles from './details.module.scss';
import { DetailsProps } from './details.types';

const Details = ({
    setScannerShow,
    setAddress,
    address,
    errors,
    fee,
    scannerShow,
    checks,
    setChecks,
    clearErrors,
}: DetailsProps) => {
    const { width } = useContext(AppContext);

    const [scannerError, setScannerError] = useState(false);

    const scannerClick = useCallback(() => {
        setScannerShow(true);
    }, [setScannerShow]);

    const handleScan = useCallback(
        (data: string | null) => {
            if (data) {
                setAddress(data);
                setScannerShow(false);
            }
        },
        [setScannerShow, setAddress],
    );

    const handleScanError = useCallback(() => {
        setScannerShow(false);
        setScannerError(true);
    }, [setScannerShow]);

    const handleCheckbox = useCallback(
        (check: boolean, idx: number) => {
            const newChecks = [...checks];
            newChecks[idx] = check;
            setChecks(newChecks);
        },
        [checks, setChecks],
    );

    const onChange = useCallback(
        (value: string) => {
            clearErrors();
            setAddress(value);
        },
        [clearErrors, setAddress],
    );

    return (
        <div className={styles.details}>
            {scannerShow ? (
                <QrReader
                    delay={300}
                    onError={handleScanError}
                    onScan={handleScan}
                    style={{ width: '100%' }}
                />
            ) : (
                <>
                    {scannerError ? (
                        <p className={styles.scannerError}>
                            Scanner error occurred
                        </p>
                    ) : (
                        ''
                    )}

                    <Input
                        placeholder="Crypto deposit address"
                        append={width < 1000 ? Scanner : null}
                        appendClick={width < 1000 ? scannerClick : null}
                        value={address}
                        change={onChange}
                        margin={`20px 0`}
                        errorMessage={errors.address}
                    />
                    <div className={styles.feeWrapper}>
                        <div className={styles.feeItem}>
                            <div className={styles.feeItemInside}>
                                <p className={styles.feeItemInsideText}>
                                    Network
                                </p>
                                <p className={styles.feeItemInsideValue}>
                                    Ethereum
                                </p>
                            </div>
                        </div>

                        <div
                            className={`${styles.feeItem} ${styles.feeTooltip}`}>
                            <span className={styles.tooltipItem}>
                                {`Fee: ${fee?.value} USDC`}
                            </span>
                            <div className={styles.feeItemInside}>
                                <p className={styles.feeItemInsideText}>Fee</p>
                                <p className={styles.feeItemInsideValue}>
                                    {Number(fee?.value)?.toFixed(4)} USDC
                                </p>
                            </div>
                            <p className={styles.feeHint}>
                                ≈ {fee?.usd?.toFixed(4)} USD
                            </p>
                        </div>
                    </div>
                    <div className={styles.listContainer}>
                        <Checkbox
                            message="USDC will be transferred as an ERC-20 token on
                            the Ethereum network to your provided address."
                            check={checks[0]}
                            setCheck={check => handleCheckbox(check, 0)}
                            checkError={errors['check0']}
                            id="checkbox1"
                            name="checkbox1"
                        />
                        <Checkbox
                            message="Ensure your address is compatible with ERC-20
                            USDC tokens."
                            check={checks[1]}
                            setCheck={check => handleCheckbox(check, 1)}
                            checkError={errors['check1']}
                            id="checkbox2"
                            name="checkbox2"
                        />
                        <Checkbox
                            message="Ensure that you can maintain access to your
                            ERC-20 address for at least 6 months
                            post-transaction."
                            check={checks[2]}
                            setCheck={check => handleCheckbox(check, 2)}
                            checkError={errors['check2']}
                            id="checkbox3"
                            name="checkbox3"
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default Details;
