import styles from './input.module.scss';

import { useMemo } from 'react';
import { ReactSVG } from 'react-svg';
import { InputProps } from './input.types';

const Input = <T,>({
    placeholder,
    append,
    appendClasses,
    appendClick,
    change,
    classes,
    defaultValue,
    disabled,
    error,
    errorMessage,
    hint,
    label,
    margin,
    prepend,
    type = 'text',
    value,
}: InputProps<T>) => {
    const setInputClasses = useMemo(
        () =>
            classes ??
            `${styles.input} ${disabled ? styles.inputDisabled : ''} ${
                errorMessage || error ? styles.inputError : ''
            } ${prepend ? styles.prependPadding : ''}`,
        [classes, disabled, errorMessage, error, prepend],
    );

    const setAppendImgClasses = useMemo(
        () => appendClasses ?? `${styles.append} ${styles.appendImage}`,
        [appendClasses],
    );

    const setAppendTextClasses = useMemo(
        () =>
            appendClasses ??
            `${styles.append} ${styles.appendText} ${
                errorMessage || error ? styles.appendTextError : ''
            }`,
        [appendClasses, error, errorMessage],
    );
    const setAppend = () => {
        const ext = ['.png', '.svg', '.jpg'];
        if (!append) return;

        const isImage =
            typeof append === 'string'
                ? ext.some(e => append.toLowerCase().endsWith(e))
                : false;

        return isImage ? (
            <img
                className={setAppendImgClasses}
                src={append as string}
                alt="icon"
                onClick={appendClick ? () => appendClick() : null}
                style={{ cursor: appendClick ? 'pointer' : 'default' }}
            />
        ) : (
            <span
                className={setAppendTextClasses}
                onClick={appendClick ? () => appendClick() : null}
                style={{ cursor: appendClick ? 'pointer' : 'default' }}>
                {append}
            </span>
        );
    };

    const setPrepend = () => {
        const ext = ['.png', '.svg', '.jpg'];
        if (!prepend) return;

        const isImage = ext.some(e => prepend.toLowerCase().endsWith(e));

        if (isImage && prepend.toLowerCase().endsWith('.svg')) {
            return (
                <ReactSVG
                    className={`${styles.prepend} ${
                        error || errorMessage ? styles.prependError : ''
                    }`}
                    src={prepend}
                />
            );
        }
    };

    const setHint = () => {
        if (!errorMessage) {
            return hint ? <p className={styles.hint}>{hint}</p> : null;
        }
        return null;
    };

    const setErrorMessage = () =>
        errorMessage ? (
            <p className={styles.errorMessage}>{errorMessage}</p>
        ) : null;

    return (
        <>
            <div style={{ margin: margin }}>
                <div className={styles.inputWrapper}>
                    {setPrepend()}
                    {label ? (
                        <label className={styles.label}>{label}</label>
                    ) : (
                        ''
                    )}
                    <input
                        type={type}
                        className={`${setInputClasses} ${
                            label ? styles.inputLabel : ''
                        }`}
                        placeholder={placeholder}
                        onChange={e => change(e.target.value as unknown as T)}
                        value={value}
                        readOnly={disabled}
                        defaultValue={defaultValue}
                    />
                    {setAppend()}
                </div>
                {setErrorMessage()}
                {setHint()}
            </div>
        </>
    );
};

export default Input;
