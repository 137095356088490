import Check from 'assets/img/status/check.svg';
import Empty from 'assets/img/status/empty.svg';
import { useCallback } from 'react';
import { ReactSVG } from 'react-svg';
import styles from './redeemBtn.module.scss';
import { RedeemBtnProps, RedeemStatusType } from './redeemBtn.types';

const getClassname = (status: RedeemStatusType) => {
    switch (status) {
        case 'passed':
            return styles.passed;
        case 'progress':
            return styles.progress;
        case 'waiting':
            return styles.waiting;
        case 'warning':
            return styles.warning;
        case 'error':
            return styles.error;
        case 'pending':
        default:
            return styles.pending;
    }
};

const getIcon = (status: RedeemStatusType) => {
    switch (status) {
        case 'passed':
            return (
                <div className={styles.icon}>
                    <ReactSVG src={Check} />
                </div>
            );
        case 'warning':
            return <span>Retry</span>;
        case 'error':
            return <span>KYC Failed</span>;
        default:
            return (
                <div className={styles.icon}>
                    <ReactSVG src={Empty} />
                </div>
            );
    }
};

export const RedeemBtn = <T extends string>({
    label,
    onClick,
    status,
    position,
    id,
    clickStatuses = [],
}: RedeemBtnProps<T>) => {
    const handleClick = useCallback(() => {
        onClick(id);
    }, [onClick, id]);

    return (
        <div className={`${styles.btnContainer} ${getClassname(status)}`}>
            {position !== undefined && (
                <div className={styles.position}>{position}</div>
            )}
            <button
                className={`${styles.button} ${
                    clickStatuses.includes(status) ? styles.pointer : ''
                }`}
                onClick={handleClick}>
                {label}
                {getIcon(status)}
            </button>
        </div>
    );
};
