import {
    DefaultService as Base,
    OpenAPI as BaseAPI,
} from 'services/generated/base';

export const setToken = (token: string) => {
    BaseAPI.TOKEN = token;
    // StakeAPI.TOKEN = token;
};

export const setup = () => {
    BaseAPI.BASE = process.env.REACT_APP_NEW_BACKEND_URL;
    // StakeAPI.BASE = process.env.REACT_APP_NEW_BACKEND_URL;
    setToken(localStorage.getItem('token'));
};

const user = {
    main: () => null,
    isLoggedIn: Base.legacyUserControllerIsUserLoggedIn,
    transactions: () => null,
    changePassword: Base.legacyUserControllerChangePassword,
    forgotPassword: Base.legacyUserControllerStartPasswordChange,
};

const signIn = {
    verify: Base.legacyUserControllerSignUserIn,
    resend: Base.legacyUserControllerSignInRefresh,
    start: Base.legacyUserControllerStartSignIn,
};

const launchpad = {
    summary: () => null,
    buy: () => null,
    myReferrals: () => null,
    generateReferralCode: () => null,
};

const chart = () => null;

const global = () => null;

const signOut = Base.legacyUserControllerSignUserOut;

const kyc = Base.legacyControllerKyc;

const staking = {
    history: () => null,
    estimateImpact: () => null,
    legacy: () => null,
    myStake: () => null,
    details: () => null,
    stake: () => null,
    unstake: () => null,
};

const tokens = () => null;

const voting = {
    submit: () => null,
    list: () => null,
};

const deposit = {
    address: () => null,
};

const withdraw = {
    fee: () => null,
    withdrawalFee: () => null,
    order: () => null,
    confirm: () => null,
    verify: () => null,
    unlockTime: () => null,
};

const signUp = {
    verify: () => null,
    resend: () => null,
    confirmEmail: () => null,
    start: () => null,
};

const swaps = {
    availableSwaps: () => null,
    swap: () => null,
};

const university = {
    courses: () => null,
    coursesPublic: () => null,
    userAccountPurchase: () => null,
    advcashPurchase: () => null,
};

const twitter = {
    legacyTimeline: () => null,
    timeline: () => null,
};

const delist = {
    stage: Base.redemptionControllerGetStage, // done
    acceptTor: Base.redemptionControllerAcceptTor, // done
    withdraw: Base.redemptionControllerWithdraw, // done
    fee: Base.redemptionControllerFee, // done
    unlockTime: Base.redemptionControllerWithdrawUnlockTime, // done
    code: Base.redemptionControllerWithdrawRequestCode, // done
    balances: Base.redemptionControllerGetBalances, // done
    passedStages: Base.redemptionControllerGetPassedStages, // done
    form: Base.redemptionControllerPassForm, // done
    acceptBasicStage: Base.redemptionControllerAcceptBasicStage, // done
    uploadTor: Base.redemptionControllerUploadToR, // done somewhere else
};

export const api = {
    user,
    chart,
    global,
    signOut,
    signIn,
    launchpad,
    kyc,
    staking,
    tokens,
    voting,
    deposit,
    withdraw,
    signUp,
    swaps,
    twitter,
    university,
    delist,
};
