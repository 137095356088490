import styles from './infoText.module.scss';
import { InfoTextProps } from './infoText.types';

export const InfoText = ({ type }: InfoTextProps) => {
    if (!type) return null;

    if (type === 'success') {
        return (
            <p className={`${styles.pinCodeInfo} ${styles.pinCodeInfoSuccess}`}>
                Code correct!
            </p>
        );
    }

    return (
        <p className={`${styles.pinCodeInfo} ${styles.pinCodeInfoError}`}>
            Code incorrect!
        </p>
    );
};
