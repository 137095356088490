import RedemptionPdf from 'assets/redemption.pdf';
import { RedeemFormContext } from 'contexts/redeemFormContext';
import { useContext } from 'react';
import styles from './downloadTor.module.scss';

export const DownloadTor = () => {
    const { handleDownloadTor } = useContext(RedeemFormContext);

    return (
        <>
            <p>
                Please download, read and{' '}
                <span className={styles.special}>
                    <strong>sign</strong>
                </span>{' '}
                the Terms of Late Redemption.
            </p>
            <div className={styles.buttonContainer}>
                <a
                    href={RedemptionPdf}
                    onClick={handleDownloadTor}
                    download="Statements of the Applicant for STC Token Redemption.pdf"
                    className={styles.btn}>
                    Download
                </a>
            </div>
        </>
    );
};
