import { LegacyRef, forwardRef } from 'react';
import { ReactSVG } from 'react-svg';

import arrowLeft from 'assets/img/arrow-left-alt.svg';
import escape from 'assets/img/x.svg';

import styles from './header.module.scss';
import { HeaderProps } from './header.types';

const Header = forwardRef(
    (
        { text, className, gray, onClose }: HeaderProps,
        ref?: LegacyRef<HTMLDivElement>,
    ) => {
        return (
            <div
                className={`${styles.header} modalHeader ${className}`}
                ref={ref}>
                <button className={styles.exitMobile} onClick={onClose}>
                    <ReactSVG src={arrowLeft} />
                </button>
                <span>
                    {text}{' '}
                    {gray ? (
                        <span className={styles.gray}>
                            <span className={styles.dot}>•</span> {gray}
                        </span>
                    ) : (
                        ''
                    )}
                </span>
                <button className={styles.x} onClick={onClose}>
                    <img src={escape} alt="escape" />
                </button>
            </div>
        );
    },
);

Header.displayName = 'Header';

export default Header;
