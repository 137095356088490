import Button from 'components/global/button/button';
import { RedeemFormContext } from 'contexts/redeemFormContext';
import { useContext } from 'react';
import styles from './completeKyc.module.scss';

export const CompleteKyc = () => {
    const { currentStep, handleKycApprove } = useContext(RedeemFormContext);

    return currentStep?.status === 'error' ? (
        <p>
            Verification declined, please contact us{' '}
            <a href="https://studentcoin.zendesk.com">here</a>.
        </p>
    ) : (
        <>
            <p>
                Screenshots or digital copies are not acceptable. If you try to
                complete KYC using a screenshot of your documents, the KYC will
                be declined.
            </p>
            <div className={styles.buttonContainer}>
                <Button
                    text="Verify"
                    type="primary"
                    onClick={handleKycApprove}
                />
            </div>
        </>
    );
};
