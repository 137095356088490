/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptBasicStageRequestDTO } from '../models/AcceptBasicStageRequestDTO';
import type { AcceptBasicStageResponseDTO } from '../models/AcceptBasicStageResponseDTO';
import type { AcceptTorRequestDTO } from '../models/AcceptTorRequestDTO';
import type { AcceptTorResponseDTO } from '../models/AcceptTorResponseDTO';
import type { AssignVeriffSessionsBackupsRequestDTO } from '../models/AssignVeriffSessionsBackupsRequestDTO';
import type { AssignVeriffSessionsBackupsResponseDTO } from '../models/AssignVeriffSessionsBackupsResponseDTO';
import type { BalancesResponseDTO } from '../models/BalancesResponseDTO';
import type { CancelPendingUnstakeRequestDTO } from '../models/CancelPendingUnstakeRequestDTO';
import type { ChangeEmailRequestDTO } from '../models/ChangeEmailRequestDTO';
import type { ChangeHotWalletStatusRequestDTO } from '../models/ChangeHotWalletStatusRequestDTO';
import type { ChangePasswordRequestDTO } from '../models/ChangePasswordRequestDTO';
import type { ChangePasswordResponseDTO } from '../models/ChangePasswordResponseDTO';
import type { CoursePurchaseConfigCreateDTO } from '../models/CoursePurchaseConfigCreateDTO';
import type { CoursePurchaseConfigModifyDTO } from '../models/CoursePurchaseConfigModifyDTO';
import type { CreateRoleRequestDTO } from '../models/CreateRoleRequestDTO';
import type { CreateSwapConfigDTO } from '../models/CreateSwapConfigDTO';
import type { CreateUserGroupRequestDTO } from '../models/CreateUserGroupRequestDTO';
import type { CreateVotingCampaignRequestDTO } from '../models/CreateVotingCampaignRequestDTO';
import type { DeleteUserGroupRequestDTO } from '../models/DeleteUserGroupRequestDTO';
import type { EditRolePermissionsRequestDTO } from '../models/EditRolePermissionsRequestDTO';
import type { EditRoleUserGroupsRequestDTO } from '../models/EditRoleUserGroupsRequestDTO';
import type { EthAddressDataDTO } from '../models/EthAddressDataDTO';
import type { FeeResponseDTO } from '../models/FeeResponseDTO';
import type { FXModifyBalanceRequestDTO } from '../models/FXModifyBalanceRequestDTO';
import type { FXModifyOracleRequestDTO } from '../models/FXModifyOracleRequestDTO';
import type { GetVeriffSessionsWithoutBackupsResponseDTO } from '../models/GetVeriffSessionsWithoutBackupsResponseDTO';
import type { HealthzResponseDTO } from '../models/HealthzResponseDTO';
import type { IsLoggedInResponseDTO } from '../models/IsLoggedInResponseDTO';
import type { LegacyKycResponseDTO } from '../models/LegacyKycResponseDTO';
import type { ModifyCampaignConfigurationRequestDTO } from '../models/ModifyCampaignConfigurationRequestDTO';
import type { ModifyDistributionsRequestDTO } from '../models/ModifyDistributionsRequestDTO';
import type { ModifySwapConfigDTO } from '../models/ModifySwapConfigDTO';
import type { ModifyUserBalanceRequestDTO } from '../models/ModifyUserBalanceRequestDTO';
import type { PassedStagesResposeDTO } from '../models/PassedStagesResposeDTO';
import type { PreSignInResponseDTO } from '../models/PreSignInResponseDTO';
import type { RedemptionFormRequestDTO } from '../models/RedemptionFormRequestDTO';
import type { RedemptionFormResponseDTO } from '../models/RedemptionFormResponseDTO';
import type { ReleasePendingFundsRequestDTO } from '../models/ReleasePendingFundsRequestDTO';
import type { RemoveOrAssignRoleToUserRequestDTO } from '../models/RemoveOrAssignRoleToUserRequestDTO';
import type { RemoveOrAssignUserGroupToUserRequestDTO } from '../models/RemoveOrAssignUserGroupToUserRequestDTO';
import type { RemoveVotingCampaignRequestDTO } from '../models/RemoveVotingCampaignRequestDTO';
import type { ReviewWithdrawalRequestDTO } from '../models/ReviewWithdrawalRequestDTO';
import type { RoleIdParamDTO } from '../models/RoleIdParamDTO';
import type { SignInRefreshRequestDTO } from '../models/SignInRefreshRequestDTO';
import type { SignInRefreshResponseDTO } from '../models/SignInRefreshResponseDTO';
import type { SignInRequestDTO } from '../models/SignInRequestDTO';
import type { SignInResponseDTO } from '../models/SignInResponseDTO';
import type { SignOutResponseDTO } from '../models/SignOutResponseDTO';
import type { StageResponseDTO } from '../models/StageResponseDTO';
import type { StartPasswordChangeRequestDTO } from '../models/StartPasswordChangeRequestDTO';
import type { StartPasswordChangeResponseDTO } from '../models/StartPasswordChangeResponseDTO';
import type { StartSignInRequestDTO } from '../models/StartSignInRequestDTO';
import type { StartSignInResponseDTO } from '../models/StartSignInResponseDTO';
import type { SwapReverseRequestDTO } from '../models/SwapReverseRequestDTO';
import type { UnstakeFromUserRequestDTO } from '../models/UnstakeFromUserRequestDTO';
import type { UserAdminLoginRequestDTO } from '../models/UserAdminLoginRequestDTO';
import type { UserCoursesModifyActionDTO } from '../models/UserCoursesModifyActionDTO';
import type { UserIdSearchDTO } from '../models/UserIdSearchDTO';
import type { UserRedemptionParameterModifyRequestDTO } from '../models/UserRedemptionParameterModifyRequestDTO';
import type { UserSearchRequestDTO } from '../models/UserSearchRequestDTO';
import type { WithdrawalAllButtonRequestDTO } from '../models/WithdrawalAllButtonRequestDTO';
import type { WithdrawalButtonRequestDTO } from '../models/WithdrawalButtonRequestDTO';
import type { WithdrawalRedemptionRequestDTO } from '../models/WithdrawalRedemptionRequestDTO';
import type { WithdrawalResponseDTO } from '../models/WithdrawalResponseDTO';
import type { WithdrawalSimpleResponseDTO } from '../models/WithdrawalSimpleResponseDTO';
import type { WithdrawUnlockTimeResponseDTO } from '../models/WithdrawUnlockTimeResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * @returns HealthzResponseDTO
     * @throws ApiError
     */
    public static healthCheckControllerHealthz(): CancelablePromise<HealthzResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/healthz',
        });
    }

    /**
     * Password change ***********************
     * Triggers email with token for password reset.
     * @param requestBody
     * @returns StartPasswordChangeResponseDTO
     * @throws ApiError
     */
    public static legacyUserControllerStartPasswordChange(
        requestBody: StartPasswordChangeRequestDTO,
    ): CancelablePromise<StartPasswordChangeResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/legacy/user/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Password reset endpoint. This is the second stage.
     * Token should be token from email after calling the `forgot-password` endpoint.
     * @param requestBody
     * @returns ChangePasswordResponseDTO
     * @throws ApiError
     */
    public static legacyUserControllerChangePassword(
        requestBody: ChangePasswordRequestDTO,
    ): CancelablePromise<ChangePasswordResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/legacy/user/change-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Sign in ***********************
     * This endpoint was probably used to prevent bots in the old backend.
     * It creates a JWT that has practically no expiration date. Since
     * the mobile application depends on it, it must stay.
     * @returns PreSignInResponseDTO
     * @throws ApiError
     */
    public static legacyUserControllerPreSignIn(): CancelablePromise<PreSignInResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/legacy/user/sign-in',
        });
    }

    /**
     * Actually starts the process of authenticating the user.
     *
     * Triggers sending email, verifies the password and provides token for the second step `2fa` endpoint.
     * @param requestBody
     * @returns StartSignInResponseDTO
     * @throws ApiError
     */
    public static legacyUserControllerStartSignIn(
        requestBody: StartSignInRequestDTO,
    ): CancelablePromise<StartSignInResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/legacy/user/sign-in',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Request new 2FA code
     * @param requestBody
     * @returns SignInRefreshResponseDTO
     * @throws ApiError
     */
    public static legacyUserControllerSignInRefresh(
        requestBody: SignInRefreshRequestDTO,
    ): CancelablePromise<SignInRefreshResponseDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/legacy/user/sign-in/2fa',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Provide 2fa code to finish sign in. Returns JWT token.
     * @param requestBody
     * @returns SignInResponseDTO
     * @throws ApiError
     */
    public static legacyUserControllerSignUserIn(
        requestBody: SignInRequestDTO,
    ): CancelablePromise<SignInResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/legacy/user/sign-in/2fa',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Previous version of the website was server-side rendered
     * and this is why this endpoint exists.
     * @returns SignOutResponseDTO
     * @throws ApiError
     */
    public static legacyUserControllerSignUserOut(): CancelablePromise<SignOutResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/legacy/user/sign-out',
        });
    }

    /**
     * @returns IsLoggedInResponseDTO
     * @throws ApiError
     */
    public static legacyUserControllerIsUserLoggedIn(): CancelablePromise<IsLoggedInResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/legacy/user/is-logged',
        });
    }

    /**
     * @returns LegacyKycResponseDTO
     * @throws ApiError
     */
    public static legacyControllerKyc(): CancelablePromise<LegacyKycResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/legacy/withdrawal/kyc',
        });
    }

    /**
     * @returns GetVeriffSessionsWithoutBackupsResponseDTO
     * @throws ApiError
     */
    public static kycControllerGetVeriffSessionsWithoutBackups(): CancelablePromise<GetVeriffSessionsWithoutBackupsResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/kyc/veriff-sessions-without-backups',
        });
    }

    /**
     * @param requestBody
     * @returns AssignVeriffSessionsBackupsResponseDTO
     * @throws ApiError
     */
    public static kycControllerAssignVeriffSessionsBackups(
        requestBody: AssignVeriffSessionsBackupsRequestDTO,
    ): CancelablePromise<AssignVeriffSessionsBackupsResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/kyc/assign-veriff-sessions-backups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminAuthAdminListControllerDisplayAdminsList(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/admins_list',
        });
    }

    /**
     * @param status
     * @returns any
     * @throws ApiError
     */
    public static adminAuthLoginControllerLoginPage(
        status?: 'USER_NOT_FOUND' | 'FAILED' | 'LOGIN_REQUIRED',
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/login',
            query: {
                'status': status,
            },
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminAuthLoginControllerLogin(
        formData: UserAdminLoginRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/login',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminAuthLogoutControllerLogoutPage(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/logout',
        });
    }

    /**
     * @param roleId
     * @returns any
     * @throws ApiError
     */
    public static adminAuthRolesPermissionsControllerRolePermissionsEditForm(
        roleId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/roles/permissions/{roleId}',
            path: {
                'roleId': roleId,
            },
        });
    }

    /**
     * @param roleId
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminAuthRolesPermissionsControllerEditRolePermissions(
        roleId: string,
        formData: EditRolePermissionsRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/roles/permissions/{roleId}/edit',
            path: {
                'roleId': roleId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminAuthRolesControllerRoles(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/roles',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminAuthRolesControllerDeleteRole(
        formData: RoleIdParamDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/roles/delete',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminAuthRolesControllerCreateRole(
        formData: CreateRoleRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/roles/create',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminAuthUserGroupsControllerGetUserGroups(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user_groups',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminAuthUserGroupsControllerCreateUserGroup(
        formData: CreateUserGroupRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user_groups/create',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminAuthUserGroupsControllerDeleteUserGroup(
        formData: DeleteUserGroupRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user_groups/delete',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param roleId
     * @returns any
     * @throws ApiError
     */
    public static adminAuthRolesUserGroupsControllerRoleUserGroupsEditForm(
        roleId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/roles/user_groups/details/{roleId}',
            path: {
                'roleId': roleId,
            },
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminAuthRolesUserGroupsControllerEditRoleUserGroups(
        formData: EditRoleUserGroupsRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/roles/user_groups/details/{roleId}/edit',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserChangeEmailControllerChangeEmailView(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/change_email/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminUserChangeEmailControllerChangeEmail(
        formData: ChangeEmailRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/change_email/{userId}',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserDetailsControllerUserDetails(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/details/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserDetailsControllerUserAllowKycRetry(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/details/{userId}/allow_kyc_retry',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserDetailsControllerAdminApproveKyc(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/details/{userId}/admin_approve_kyc',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminUserFindControllerUserDetailsSearchForm(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/find',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminUserFindControllerUserDetailsSearch(
        formData: UserSearchRequestDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/find',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * DataTables API endpoint. Not requested by users.
     * @param email
     * @returns any
     * @throws ApiError
     */
    public static adminUserFindControllerGetUsersDataTable(
        email: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/find/list/datatable',
            query: {
                'email': email,
            },
        });
    }

    /**
     * @param email
     * @returns any
     * @throws ApiError
     */
    public static adminUserFindControllerDisplayUsersList(
        email: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/find/list',
            query: {
                'email': email,
            },
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserModifyBalanceControllerUserDetailsModifyBalanceForm(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/modify_balance/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserReferralsControllerUserReferrals(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/referrals/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserRolesControllerUserRoles(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/roles/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminUserRolesControllerRemoveRoleFromUser(
        formData: RemoveOrAssignRoleToUserRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/roles/{userId}/remove',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminUserRolesControllerAssignRoleToUser(
        formData: RemoveOrAssignRoleToUserRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/roles/{userId}/assign',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserStakingControllerUserStakingHistory(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/staking/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminUserStakingControllerAdminUnstakeFromUser(
        formData: UnstakeFromUserRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/staking/{userId}/admin_unstake',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserTransactionsControllerUserTransactions(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/transactions/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static adminUserTransactionsControllerReverseSwap(
        userId: string,
        requestBody: SwapReverseRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/transactions/{userId}/reverse',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @param reviewOnly
     * @returns any
     * @throws ApiError
     */
    public static adminUserWithdrawalsControllerUserWithdrawals(
        userId: string,
        reviewOnly?: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/withdrawals/{userId}',
            path: {
                'userId': userId,
            },
            query: {
                'reviewOnly': reviewOnly,
            },
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserGroupsControllerGetUserGroupsForUser(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/user_groups/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminUserGroupsControllerAssignUserGroupToUser(
        formData: RemoveOrAssignUserGroupToUserRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/user_groups/{userId}/assign',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminUserGroupsControllerRemoveUserGroupFromUser(
        formData: RemoveOrAssignUserGroupToUserRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/user_groups/{userId}/remove',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserPurchasesControllerUserPurchases(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/purchases/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserCourseEnrollmentsControllerUserListEnrollments(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/courses/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static adminUserCourseEnrollmentsControllerUserCoursesModify(
        userId: string,
        requestBody: UserCoursesModifyActionDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/courses/{userId}/modify',
            path: {
                'userId': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserEthAddressesControllerUserEthAddresses(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/eth_addresses/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminUserIdFindControllerUserDetailsSearchForm(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/find-id',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminUserIdFindControllerUserDetailsSearch(
        formData: UserIdSearchDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/find-id',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserRedemptionControllerUserRedemption(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/redemption_full/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminUserRedemptionControllerUserRedemptionParameterModify(
        userId: string,
        formData: UserRedemptionParameterModifyRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/user/redemption_full/{userId}/parameter-modify',
            path: {
                'userId': userId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserFullHistoryControllerFullTxHistory(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/full_history/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @returns any
     * @throws ApiError
     */
    public static adminUserRedemptionSmallControllerUserRedemption(
        userId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/user/redemption/{userId}',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminUniversityCoursesControllerUniCourseList(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/uni/courses',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static adminUniversityCoursesControllerUniCoursePurchaseConfigCreate(
        requestBody: CoursePurchaseConfigCreateDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/uni/courses/course_purchase_config/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param configId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static adminUniversityCoursesControllerUniCoursePurchaseConfigModify(
        configId: string,
        requestBody: CoursePurchaseConfigModifyDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/uni/courses/course_purchase_config/modify/{configId}',
            path: {
                'configId': configId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param discountId
     * @returns any
     * @throws ApiError
     */
    public static adminUniversityDiscountDetailsControllerUniDiscountDetails(
        discountId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/uni/discounts/{discountId}',
            path: {
                'discountId': discountId,
            },
        });
    }

    /**
     * @param start
     * @param length
     * @param columns
     * @param search
     * @param discountId
     * @param order
     * @returns any
     * @throws ApiError
     */
    public static adminUniversityDiscountDetailsControllerGetUniDiscountGrantsDataTable(
        start: string,
        length: string,
        columns: Array<{
            name: string;
            search: {
                value: string;
            };
            data?: string;
        }>,
        search: {
            value: string;
        },
        discountId: string,
        order?: Array<{
            column: string;
            dir: 'asc' | 'desc';
        }>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/uni/discounts/{discountId}/data',
            path: {
                'discountId': discountId,
            },
            query: {
                'start': start,
                'length': length,
                'columns': columns,
                'search': search,
                'order': order,
            },
        });
    }

    /**
     * @param discountId
     * @returns any
     * @throws ApiError
     */
    public static adminUniversityDiscountDetailsControllerUniDiscountDelete(
        discountId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/uni/discounts/{discountId}/delete',
            path: {
                'discountId': discountId,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminUniversityDiscountControllerUniDiscounts(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/uni/discounts',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminUniversityDiscountControllerUniDiscountsUploadCsv(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/uni/discounts',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminAggregateBalancesControllerGetAggregateBalances(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/aggregate_balances',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminTopHoldersControllerTopHolders(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/top_holders',
        });
    }

    /**
     * @param asset
     * @param start
     * @param length
     * @param columns
     * @returns any
     * @throws ApiError
     */
    public static adminTopHoldersControllerGetTopHoldersDataTable(
        asset: string,
        start: string,
        length: string,
        columns: Array<{
            name: string;
            search: {
                value: string;
            };
            data?: string;
        }>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/top_holders/data-tables',
            query: {
                'asset': asset,
                'start': start,
                'length': length,
                'columns': columns,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminEthAddressesBalancesControllerEthAddressesBalances(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/eth_addresses_balances',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminEthAddressesBalancesControllerEthAddressesBalancesCsv(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/eth_addresses_balances/csv',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminMarketingStatisticsControllerSmtMarketingStatistics(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/smt_launchpad',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminMarketingStatisticsControllerSmtReferrals(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/smt_launchpad/referrals',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminMarketingStatisticsControllerSmtCoinbaseCommerceUtm(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/smt_launchpad/cc',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminMarketingStatisticsControllerSmtAdvcashUtm(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/smt_launchpad/adv',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminMarketingStatisticsControllerUniAdvcashUtm(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/smt_launchpad/uni_advcash',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminMarketingStatisticsControllerUniUserAccountUtm(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/smt_launchpad/uni_user_account',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminVotingListControllerVotingCampaignsList(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/voting/list',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminVotingListControllerRemoveVotingCampaign(
        formData: RemoveVotingCampaignRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/voting/list/remove',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminVotingCreateControllerCreateVotingCampaignForm(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/voting/create',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminVotingCreateControllerCreateVotingCampaign(
        formData: CreateVotingCampaignRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/voting/create',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminSystemLedgerListControllerLedgerList(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/ledger/list',
        });
    }

    /**
     * @param start
     * @param length
     * @param columns
     * @param search
     * @param order
     * @returns any
     * @throws ApiError
     */
    public static adminSystemLedgerListControllerGetLedgerListDataTable(
        start: string,
        length: string,
        columns: Array<{
            name: string;
            search: {
                value: string;
            };
            data?: string;
        }>,
        search: {
            value: string;
        },
        order?: Array<{
            column: string;
            dir: 'asc' | 'desc';
        }>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/ledger/list/data',
            query: {
                'start': start,
                'length': length,
                'columns': columns,
                'search': search,
                'order': order,
            },
        });
    }

    /**
     * @param ledgerId
     * @returns any
     * @throws ApiError
     */
    public static adminSystemLedgerDetailsControllerLedgerDetails(
        ledgerId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/ledger/details/{ledgerId}',
            path: {
                'ledgerId': ledgerId,
            },
        });
    }

    /**
     * @param start
     * @param length
     * @param columns
     * @param search
     * @param ledgerId
     * @param order
     * @returns any
     * @throws ApiError
     */
    public static adminSystemLedgerDetailsControllerGetLedgerDetailsDataTable(
        start: string,
        length: string,
        columns: Array<{
            name: string;
            search: {
                value: string;
            };
            data?: string;
        }>,
        search: {
            value: string;
        },
        ledgerId: string,
        order?: Array<{
            column: string;
            dir: 'asc' | 'desc';
        }>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/ledger/details/{ledgerId}/data',
            path: {
                'ledgerId': ledgerId,
            },
            query: {
                'start': start,
                'length': length,
                'columns': columns,
                'search': search,
                'order': order,
            },
        });
    }

    /**
     * @param fxId
     * @returns any
     * @throws ApiError
     */
    public static adminSystemFxDetailsControllerFxDetails(
        fxId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/fx/details/{fxId}',
            path: {
                'fxId': fxId,
            },
        });
    }

    /**
     * @param fxId
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminSystemFxDetailsControllerAdminModifyFxAssets(
        fxId: string,
        formData: FXModifyBalanceRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/fx/details/{fxId}/modify_balance',
            path: {
                'fxId': fxId,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param fxId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static adminSystemFxDetailsControllerAdminModifyFxOracle(
        fxId: string,
        requestBody: FXModifyOracleRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/fx/details/{fxId}/modify_oracle',
            path: {
                'fxId': fxId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminSystemFxListControllerFxList(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/fx/list',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminSystemHotWalletTransactionListControllerHotWalletsTransactionList(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/hot_wallets/transaction/list',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminSystemHotWalletControllerHotWalletsList(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/hot_wallets/list',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminSystemHotWalletControllerChangeHotWalletStatus(
        formData: ChangeHotWalletStatusRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/hot_wallets/list/change_status',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminSystemEthAddressesControllerEthAddressesList(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/eth_addresses',
        });
    }

    /**
     * DataTables API endpoint. Not requested by users.
     * @param userId
     * @returns EthAddressDataDTO
     * @throws ApiError
     */
    public static adminSystemEthAddressesControllerGetEthAddressesData(
        userId?: string,
    ): CancelablePromise<Array<EthAddressDataDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/eth_addresses/data',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @param campaignId
     * @returns any
     * @throws ApiError
     */
    public static adminPendingUnstakesControllerFlexibleStakingPendingUnstakes(
        campaignId?: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/staking/flexible',
            query: {
                'campaignId': campaignId,
            },
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminPendingUnstakesControllerCancelPendingUnstake(
        formData: CancelPendingUnstakeRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/staking/flexible/cancel_unstake',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminPendingUnstakesControllerReleasePendingFunds(
        formData: ReleasePendingFundsRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/staking/flexible/release_pending_funds',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param campaignId
     * @returns any
     * @throws ApiError
     */
    public static adminModifyDistributionControllerModifyDistributionsForm(
        campaignId: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/staking/flexible/distributions/{campaignId}',
            path: {
                'campaignId': campaignId,
            },
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminModifyDistributionControllerModifyDistributions(
        formData: ModifyDistributionsRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/staking/flexible/distributions/{campaignId}/modify',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminFlexibleStakingListControllerFlexibleStakingDetails(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/staking/flexible/details',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminFlexibleStakingListControllerModifyCampaignConfiguration(
        formData: ModifyCampaignConfigurationRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/staking/flexible/details/modify_campaign_configuration',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param reviewOnly
     * @returns any
     * @throws ApiError
     */
    public static adminIndexControllerRoot(
        reviewOnly?: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin',
            query: {
                'reviewOnly': reviewOnly,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminTransactionsUploadControllerUploadAdminTransactionsCsvForm(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/upload_admin_transactions',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminTransactionsUploadControllerUploadAdminTransactionsCsv(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/upload_admin_transactions',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminModifyBalanceControllerModifyUserBalanceForm(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/modify_user_balance',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminModifyBalanceControllerModifyUserBalance(
        formData: ModifyUserBalanceRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/modify_user_balance',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param reviewOnly
     * @returns any
     * @throws ApiError
     */
    public static adminWithdrawalsControllerWithdrawalsList(
        reviewOnly?: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/withdrawals',
            query: {
                'reviewOnly': reviewOnly,
            },
        });
    }

    /**
     * @param start
     * @param length
     * @param columns
     * @param search
     * @param userId
     * @param reviewOnly
     * @returns any
     * @throws ApiError
     */
    public static adminWithdrawalsControllerGetWithdrawalsDataTable(
        start: string,
        length: string,
        columns: Array<{
            name: string;
            search: {
                value: string;
            };
            data?: string;
        }>,
        search: {
            value: string;
        },
        userId?: string,
        reviewOnly?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/withdrawals/data',
            query: {
                'start': start,
                'length': length,
                'columns': columns,
                'search': search,
                'userId': userId,
                'reviewOnly': reviewOnly,
            },
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminWithdrawalsControllerReviewWithdrawal(
        formData: ReviewWithdrawalRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/withdrawals/review',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminWithdrawalsControllerWithdrawalMoveToReview(
        formData: WithdrawalButtonRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/withdrawals/move_to_review',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminWithdrawalsControllerWithdrawalFinalApprove(
        formData: WithdrawalButtonRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/withdrawals/final_approval',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param formData
     * @returns any
     * @throws ApiError
     */
    public static adminWithdrawalsControllerWithdrawalFinalApproveAll(
        formData: WithdrawalAllButtonRequestDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/withdrawals/final_approval_all',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminAdminTransactionsControllerAdminTransactionsList(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/admin_transactions',
        });
    }

    /**
     * @param start
     * @param length
     * @param columns
     * @param search
     * @param order
     * @returns any
     * @throws ApiError
     */
    public static adminAdminTransactionsControllerGetAdminTransactionsDataTable(
        start: string,
        length: string,
        columns: Array<{
            name: string;
            search: {
                value: string;
            };
            data?: string;
        }>,
        search: {
            value: string;
        },
        order?: Array<{
            column: string;
            dir: 'asc' | 'desc';
        }>,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/admin_transactions/data',
            query: {
                'start': start,
                'length': length,
                'columns': columns,
                'search': search,
                'order': order,
            },
        });
    }

    /**
     * @returns any
     * @throws ApiError
     */
    public static adminSwapsControllerSwapList(): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/swap/list',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static adminSwapsControllerSwapCreate(
        requestBody: CreateSwapConfigDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/swap/list/create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param swapId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static adminSwapsControllerSwapModify(
        swapId: string,
        requestBody: ModifySwapConfigDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/admin/swap/list/modify/{swapId}',
            path: {
                'swapId': swapId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param showOwnOnly
     * @returns any
     * @throws ApiError
     */
    public static adminKucoinWithdrawalsControllerWithdrawalsList(
        showOwnOnly?: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/kucoin-withdrawals',
            query: {
                'showOwnOnly': showOwnOnly,
            },
        });
    }

    /**
     * @param start
     * @param length
     * @param columns
     * @param search
     * @param showOwnOnly
     * @returns any
     * @throws ApiError
     */
    public static adminKucoinWithdrawalsControllerGetWithdrawalsDataTable(
        start: string,
        length: string,
        columns: Array<{
            name: string;
            search: {
                value: string;
            };
            data?: string;
        }>,
        search: {
            value: string;
        },
        showOwnOnly?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/admin/kucoin-withdrawals/data',
            query: {
                'start': start,
                'length': length,
                'columns': columns,
                'search': search,
                'showOwnOnly': showOwnOnly,
            },
        });
    }

    /**
     * @returns StageResponseDTO
     * @throws ApiError
     */
    public static redemptionControllerGetStage(): CancelablePromise<StageResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redemption/getStage',
        });
    }

    /**
     * @returns PassedStagesResposeDTO
     * @throws ApiError
     */
    public static redemptionControllerGetPassedStages(): CancelablePromise<PassedStagesResposeDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redemption/getPassedStages',
        });
    }

    /**
     * @returns BalancesResponseDTO
     * @throws ApiError
     */
    public static redemptionControllerGetBalances(): CancelablePromise<BalancesResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redemption/getBalances',
        });
    }

    /**
     * @param requestBody
     * @returns RedemptionFormResponseDTO
     * @throws ApiError
     */
    public static redemptionControllerPassForm(
        requestBody: RedemptionFormRequestDTO,
    ): CancelablePromise<RedemptionFormResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/redemption/passForm',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns AcceptTorResponseDTO
     * @throws ApiError
     */
    public static redemptionControllerAcceptTor(
        requestBody: AcceptTorRequestDTO,
    ): CancelablePromise<AcceptTorResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/redemption/acceptTor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns string
     * @throws ApiError
     */
    public static redemptionControllerUploadToR(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/redemption/uploadToR',
        });
    }

    /**
     * @param requestBody
     * @returns AcceptBasicStageResponseDTO
     * @throws ApiError
     */
    public static redemptionControllerAcceptBasicStage(
        requestBody: AcceptBasicStageRequestDTO,
    ): CancelablePromise<AcceptBasicStageResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/redemption/acceptBasicStage',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns FeeResponseDTO
     * @throws ApiError
     */
    public static redemptionControllerFee(): CancelablePromise<FeeResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redemption/fee',
        });
    }

    /**
     * @returns WithdrawUnlockTimeResponseDTO
     * @throws ApiError
     */
    public static redemptionControllerWithdrawUnlockTime(): CancelablePromise<WithdrawUnlockTimeResponseDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/redemption/withdraw-unlock-time',
        });
    }

    /**
     * @returns WithdrawalSimpleResponseDTO
     * @throws ApiError
     */
    public static redemptionControllerWithdrawRequestCode(): CancelablePromise<WithdrawalSimpleResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/redemption/request-code',
        });
    }

    /**
     * @param requestBody
     * @returns WithdrawalResponseDTO
     * @throws ApiError
     */
    public static redemptionControllerWithdraw(
        requestBody: WithdrawalRedemptionRequestDTO,
    ): CancelablePromise<WithdrawalResponseDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/redemption/withdraw',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
