import ModalHeader from 'components/global/modal/header/header';
import Modal from 'react-modal';
import styles from './formModal.module.scss';
import { FormModalProps } from './formModal.types';

export const FormModal = ({
    children,
    handleClose,
    isOpen,
    heading,
}: FormModalProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={handleClose}
            className={`modal-body ${styles.modal}`}
            overlayClassName="modal-overlay"
            closeTimeoutMS={0}
            ariaHideApp={false}>
            <ModalHeader text={heading} onClose={handleClose} />
            <div className={styles.content}>{children}</div>
        </Modal>
    );
};
