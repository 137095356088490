import styles from './checkboxInput.module.scss';
import { CheckboxInputProps } from './checkboxInput.types';

export const CheckboxInput = ({
    errorMsg,
    handleBlur,
    handleInputChange,
    inputId,
    inputPlaceholder,
    showErrorMsg,
    value,
    index,
}: CheckboxInputProps) => {
    return (
        <>
            <input
                type="text"
                value={value}
                placeholder={inputPlaceholder}
                className={`${styles.input} ${
                    showErrorMsg ? styles.error : ''
                }`}
                onBlur={() => handleBlur(index)}
                onChange={e => handleInputChange(e, inputId, index)}
            />
            {showErrorMsg && (
                <span className={styles.errorMsg}>{errorMsg}</span>
            )}
        </>
    );
};
