import { RedeemStatusType } from 'components/redeem/button/redeemBtn.types';
import { PassedStagesResposeDTO } from 'services/generated/base';

export type RedeemButtonId = 'accept_tor' | 'complete_kyc' | 'redeem_stc';

export type RedeemButtonType = {
    id: RedeemButtonId;
    completedId?: PassedStagesResposeDTO['stages'][0];
    label: string;
    status: RedeemStatusType;
};

export const notClickableStatuses: RedeemStatusType[] = [
    'waiting',
    'passed',
    'progress',
];

export const activeStatuses: RedeemStatusType[] = [
    'pending',
    'warning',
    'error',
    'progress',
];

export const redeemButtons: RedeemButtonType[] = [
    {
        id: 'redeem_stc',
        completedId: 'withdrawal_started',
        label: 'Reedem STC and withdraw USDC',
        status: 'pending',
    },
];
