type PromiseResult<T> = {
    status: 'pending' | 'resolved' | 'rejected';
    value?: T;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error?: any;
};

export type CancelablePromise<T> = Promise<PromiseResult<T>> & {
    cancel: () => void;
};

export const makeCancelablePromise = <T>(
    promise: Promise<T>,
): CancelablePromise<T> => {
    let hasCanceled = false;

    const cancelablePromise = new Promise<PromiseResult<T>>(
        (resolve, reject) => {
            promise
                .then(value => {
                    if (!hasCanceled) {
                        resolve({ status: 'resolved', value });
                    }
                })
                .catch(error => {
                    if (!hasCanceled) {
                        reject({ status: 'rejected', error });
                    }
                });
        },
    ) as CancelablePromise<T>;

    cancelablePromise.cancel = () => (hasCanceled = true);

    return cancelablePromise;
};
