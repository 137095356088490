import { RedeemBtn } from '../button/redeemBtn';
import { FormStepsProps } from './formSteps.types';

export const FormSteps = ({ data, handleOpenModal }: FormStepsProps) => {
    return (
        <div>
            {data.map((item, idx) => (
                <RedeemBtn
                    id={item.modalId}
                    key={item.label}
                    label={item.label}
                    status={item.status}
                    onClick={handleOpenModal}
                    position={idx + 1}
                    clickStatuses={item.clickStatuses}
                />
            ))}
        </div>
    );
};
