import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { modalScreenTransition } from 'utils/pageVariants';

import Overlay from 'views/auth/overlay/overlay';
import Change from './change/change';
import Mail from './mail/mail';
import Success from './success/success';

import styles from './resetPassword.module.scss';

const ResetPassword = () => {
    const params: {
        token?: string;
    } = useParams();

    const [state, setState] = useState('change');

    const [animatedX, setAnimatedX] = useState(0);

    useEffect(() => {
        document.title = 'Student Coin - Forgot Password';
    });

    const setView = () => {
        if (!params.token) return <Mail />;
        else if (params.token && state === 'change')
            return (
                <Change
                    setState={setState}
                    token={params.token}
                    setAnimatedX={setAnimatedX}
                />
            );
        else if (params.token && state === 'success') return <Success />;
    };

    return (
        <Overlay>
            <AnimatePresence exitBeforeEnter>
                <motion.div
                    className={styles.reset}
                    initial={{
                        opacity: 0,
                        x: animatedX,
                    }}
                    animate={{
                        opacity: 1,
                        x: 0,
                    }}
                    transition={modalScreenTransition}
                    key={state}>
                    {setView()}
                </motion.div>
            </AnimatePresence>
        </Overlay>
    );
};

export default ResetPassword;
