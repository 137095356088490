import { useEffect, useState } from 'react';
import { api } from 'services/api/api';
import { makeCancelablePromise } from 'utils/cancelablePromise';

export const useWithdraw = () => {
    const [unlockTime, setUnlockTime] = useState(0);

    useEffect(() => {
        const cancelablePromise = makeCancelablePromise(
            api.delist.unlockTime(),
        );

        cancelablePromise
            .then(res => {
                setUnlockTime(res.value.unlockTime);
            })
            .catch(() => {
                //
            });

        return () => {
            cancelablePromise.cancel();
        };
    }, []);
    return { unlockTime };
};
