import styles from './slide.module.scss';
import { SlideProps } from './slide.types';

const Slide = ({ header, image, text }: SlideProps) => {
    return (
        <div className={styles.slide}>
            {image}
            <div className={styles.slideTextWrapper}>
                <p className={styles.slideHeader}>{header}</p>
                <p className={styles.slideText}>{text}</p>
            </div>
        </div>
    );
};

export default Slide;
