import { RedeemTemplate } from '../template/redeemTemplate';
import styles from './error.module.scss';

export const Error = () => {
    return (
        <RedeemTemplate>
            <div className={styles.content}>
                An unexpected error occurred. Please try again later.
            </div>
        </RedeemTemplate>
    );
};
