import DocumentIcon from 'assets/img/document.svg';
import styles from './DropContent.module.scss';
import { DropContentProps } from './DropContent.types';

export const DropContent = ({ hover, file }: DropContentProps) => {
    if (hover) {
        return (
            <div className={styles.uploadTextContainer}>
                <span className={styles.uploadText}>Drop here</span>
            </div>
        );
    }

    if (file) {
        return (
            <div className={styles.uploadTextContainer}>
                <span className={styles.uploadText}>
                    Uploaded{' '}
                    <span className={styles.underline}>{file.name}</span>
                </span>
            </div>
        );
    }

    return (
        <>
            <div className={styles.uploadTextContainer}>
                <img src={DocumentIcon} alt="document" />
                <span className={styles.uploadText}>Upload or drop a file</span>
            </div>
            <span>PDF</span>
        </>
    );
};
