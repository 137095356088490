import { formatQuantity, formatUsdcPrice } from '../data/redeemData.utils';
import { RedeemTooltip } from '../tooltip/redeemTooltip';
import styles from './redeemTable.module.scss';
import { RedeemTableProps } from './redeemTable.types';

export const RedeemTable = ({ data, totalStc }: RedeemTableProps) => {
    return (
        <table className={styles.table}>
            <thead className={styles.head}>
                <th></th>
                <th>price</th>
                <th>quantity</th>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div className={styles.labelTooltip}>
                            <span>STC from all bonuses</span>
                            <RedeemTooltip
                                tooltip={`"STC from all bonuses" refers to the total amount of $STC tokens received for free through various bonuses, including registration, affiliate, recommendation, preferred payment method, bulk purchase, airdrop, and other other related bonuses.`}
                            />
                        </div>
                    </td>
                    <td>{formatUsdcPrice(data?.bonuses?.price)}</td>
                    <td>{formatQuantity(data?.bonuses?.amount)}</td>
                </tr>
                <tr>
                    <td>
                        <div className={styles.labelTooltip}>
                            <span>STC from purchase</span>
                            <RedeemTooltip
                                tooltip={`"STC from purchase" indicates the total $STC you've bought throughout your account's history, covering all Launchpads and transactions post-Launchpad. This figure is net of bonuses, representing the actual amount purchased at listed prices. These tokens represent the ones you've directly purchased within the STC Wallet.`}
                            />
                        </div>
                    </td>
                    <td>{formatUsdcPrice(data?.purchase?.price)}</td>
                    <td>{formatQuantity(data?.purchase?.amount)}</td>
                </tr>
                <tr>
                    <td>
                        <div className={styles.labelTooltip}>
                            <span>STC from PHP bonus</span>
                            <RedeemTooltip
                                tooltip={`"STC from PHP bonus" refers to tokens earned by participating in the STC Premium Holder Program, where locking your $STC could yield up to a 19.2% cumulative reward from April 2021. Tokens involved in the PHP are categorized separately.`}
                            />
                        </div>
                    </td>
                    <td>{formatUsdcPrice(data?.staking?.price)}</td>
                    <td>{formatQuantity(data?.staking?.amount)}</td>
                </tr>
                <tr>
                    <td>
                        <div className={styles.labelTooltip}>
                            <span>
                                STC, ETH, SMT, LEAN from all other sources
                            </span>
                            <RedeemTooltip
                                tooltip={`"STC from all other sources" includes $STC gained from various activities, alongside an automatic conversion of all your holdings to $STC, consolidating them into one USDC withdrawal. This field contains $STC from deposits, swaps, post 2021 purchases, and other sources, as well as automatic conversions from $ETH, $SMT, $LEAN, $USDC, and $USDP to $STC (and subsequently to USDC). The market-based conversion rates are detailed in the announcement. The automatic conversion of other holdings to $STC was implemented because the value of over 99% of subholdings was insufficient to cover withdrawal fees.`}
                            />
                        </div>
                    </td>
                    <td>{formatUsdcPrice(data?.other?.price)}</td>
                    <td>{formatQuantity(data?.other?.amount)}</td>
                </tr>
                <tr>
                    <td>
                        <div className={styles.labelTooltip}>
                            <span>Total STC to redeem</span>
                            <RedeemTooltip
                                tooltip={`Total STC represents all of your $STC holdings from every source, including those automatically converted from $ETH, $SMT, $LEAN, $USDC, and $USDP  (if you held any of these tokens at STC Wallet).`}
                            />
                        </div>
                    </td>
                    <td>{formatUsdcPrice(data?.averagePrice)}</td>
                    <td>{formatQuantity(totalStc)}</td>
                </tr>
                <tr className={styles.highlight}>
                    <td>
                        <div className={styles.labelTooltip}>
                            <div className={styles.inline}>
                                <span>Your Aggregate Redemption Price</span>
                                <RedeemTooltip
                                    tooltip={`Your Aggregate Redemption Price is the weighted average price of your total STC holdings eligible for redemption into USDC. It's tailored to you, factoring in your holding structure, account history, and participation in community programs.`}
                                />
                            </div>
                        </div>
                    </td>
                    <td>
                        <div>{formatUsdcPrice(data?.averagePrice)}</div>
                    </td>
                    <td>
                        <div>{formatUsdcPrice(data?.usdTotal)}</div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
