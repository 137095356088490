import { initialSteps } from 'components/redeem/content/RedeemFormContextProvider/RedeemFormContenxtProvider.data';
import { ModalIdType } from 'components/redeem/formBox/formBox.types';

export const formModalHeading = (id: ModalIdType) => {
    const step = initialSteps.find(step => step.modalId === id);

    if (!step) return null;

    return step.label;
};
