import { DelistContext } from 'contexts/delistContext';
import { useCallback, useContext, useState } from 'react';
import { redeemButtons } from 'views/redeem/redeem.data';
import { RedeemBtn } from '../button/redeemBtn';
import { RedeemData } from '../data/redeemData';
import { RedeemTemplate } from '../template/redeemTemplate';
import { RedeemWithdraw } from '../withdraw/redeemWithdraw';
import { useWithdraw } from './redeemBox.hooks';
import styles from './redeemBox.module.scss';

export const RedeemBox = () => {
    const [withdrawModalOpen, setWithdrawModalOpen] = useState(false);

    const { data, email, totalStc } = useContext(DelistContext);

    const { unlockTime } = useWithdraw();

    const handleBtnClick = useCallback(() => {
        setWithdrawModalOpen(true);
    }, []);

    const handleWithdrawModalClose = useCallback(() => {
        setWithdrawModalOpen(false);
    }, []);

    return (
        <RedeemTemplate>
            <h2>Your account Redemption statistics:</h2>
            <RedeemData data={data} totalStc={totalStc} />
            <div className={styles.stepsContainer}>
                {redeemButtons.map(redeemButton => (
                    <RedeemBtn
                        key={redeemButton.id}
                        label={redeemButton.label}
                        status={redeemButton.status}
                        onClick={handleBtnClick}
                        id={redeemButton.id}
                    />
                ))}
            </div>
            <RedeemWithdraw
                open={withdrawModalOpen}
                close={handleWithdrawModalClose}
                unlockTime={unlockTime}
                value={data?.usdTotal}
                email={email}
            />
        </RedeemTemplate>
    );
};
