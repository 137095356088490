export const handleKeyUp = (
    key: number | undefined,
    index: number,
    type: string,
) => {
    const inputs = document.querySelectorAll('input[name=code]');
    if (key === 8 && type === 'back') {
        if (index - 1 >= 0) {
            (inputs.item(index - 1) as HTMLInputElement).focus();
            (inputs.item(index - 1) as HTMLInputElement).select();
        }
    } else if (index + 1 < inputs.length && type === 'next')
        (inputs.item(index + 1) as HTMLInputElement).focus();
};
