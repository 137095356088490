import ArrowRight from 'assets/img/arrow-right-blue.svg';
import Spinner from 'assets/img/auth/spinner.svg';
import styles from './NextComponent.module.scss';
import { NextComponentProps } from './NextComponent.types';

export const NextComponent = ({
    clickNext,
    isLoading,
    nextDisable,
    nextType,
    nextContent,
}: NextComponentProps) => {
    if (nextContent) {
        return (
            <button
                className={`${styles.next} ${styles[nextType]} ${
                    nextDisable ? styles.nextDisable : ''
                }`}
                onClick={nextDisable ? null : () => clickNext()}>
                {nextContent}
            </button>
        );
    }

    return (
        <button
            className={`${styles.next} ${styles[nextType]} ${
                nextDisable ? styles.nextDisable : ''
            }`}
            onClick={nextDisable ? null : () => clickNext()}>
            {!isLoading ? (
                <>
                    <span>continue</span>
                    <img src={ArrowRight} alt="arrow right" />
                </>
            ) : (
                <img src={Spinner} alt="spiner" className={styles.loading} />
            )}
        </button>
    );
};
