import { AcceptAnnouncement } from './acceptAnnouncement/acceptAnnouncement';
import { AcceptLateWithdrawal } from './acceptLateWithdrawal/acceptLateWithdrawal';
import { AcceptTerms } from './acceptTerms/acceptTor';
import { CompleteKyc } from './completeKyc/completeKyc';
import { DownloadTor } from './downloadTor/downloadTor';
import { FormModalContentProps } from './formModalContent.types';
import { KycInstruction } from './kycInstruction/kycInstruction';
import { UploadTor } from './uploadTor/uploadTor';

export const FormModalContent = ({ id }: FormModalContentProps) => {
    switch (id) {
        case 'kyc-instruction':
            return <KycInstruction />;
        case 'complete-kyc':
            return <CompleteKyc />;
        case 'download-tor':
            return <DownloadTor />;
        case 'upload-tor':
            return <UploadTor />;
        case 'accept-terms':
            return <AcceptTerms />;
        case 'accept-late-withdrawal':
            return <AcceptLateWithdrawal />;
        case 'accept-announcement':
            return <AcceptAnnouncement />;
        default:
            return null;
    }
};
