import Input from 'components/global/input/input';
import { RedeemFormContext } from 'contexts/redeemFormContext';
import { useAcceptTermsInput } from 'hooks/useAcceptTermsInput';
import { useContext } from 'react';
import { LoadingButton } from '../LoadingButton/loadingButton';
import styles from './acceptAnnouncement.module.scss';

export const AcceptAnnouncement = () => {
    const { handleAcceptAnnouncement, stageLoading } =
        useContext(RedeemFormContext);

    const { error, handleChange, handleSend } = useAcceptTermsInput(
        handleAcceptAnnouncement,
    );

    return (
        <>
            <p>
                Please confirm that you have read and accept all the conditions
                of the announcement at{' '}
                <a
                    href="https://www.studentcoin.org/"
                    target="_blank"
                    rel="noreferrer">
                    https://www.studentcoin.org/
                </a>
                , including the token conversion rates.
            </p>
            <span className={styles.label}>
                Please type &quot;I agree and confirm&quot;
            </span>
            <Input placeholder="I agree and confirm" change={handleChange} />
            {error && (
                <span className={styles.error}>
                    The input must be &quot;I agree and confirm&quot;
                </span>
            )}
            <div className={styles.btnContainer}>
                <LoadingButton
                    content="Send"
                    onClick={handleSend}
                    isLoading={stageLoading}
                />
            </div>
        </>
    );
};
