import { useEffect } from 'react';
import styles from './resend.module.scss';
import { ResendProps } from './resend.types';

const Resend = ({ resendFunction, setTime, time }: ResendProps) => {
    useEffect(() => {
        let timeout = null;

        const countDown = () => {
            if (time > 0) {
                const toReturn = (time: number) => {
                    if (time > 0) time--;
                    else clearTimeout(timeout);
                    return time;
                };
                const value = toReturn(time);
                setTime(value);
            }
        };

        timeout = setTimeout(() => countDown(), 1000);

        return () => clearTimeout(timeout);
    }, [time, setTime]);

    return time > 0 ? (
        <p className={styles.resend}>Resend code in {time.toFixed(0)}s</p>
    ) : (
        <p
            className={`${styles.resend} ${styles.resendPointer}`}
            onClick={resendFunction}>
            Resend mail
        </p>
    );
};

export default Resend;
