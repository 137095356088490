import ArrowRight from 'assets/img/arrow-right-blue.svg';
import { RedeemTemplate } from '../template/redeemTemplate';
import styles from './boxLessValue.module.scss';

export const BoxLessValue = () => {
    return (
        <RedeemTemplate>
            <p>
                Thank you for visiting your STC Wallet. Student Coin is
                currently redeeming STC Tokens from the market. Our records show
                you have no holdings in your STC Wallet, indicating any previous
                holdings were withdrawn or never existed. Your account will
                close in 6 months, with all user data permanently erased. Thanks
                for your involvement, and best wishes for the future.
            </p>
            <div className={styles.linkRow}>
                <a
                    href="https://studentcoin.org"
                    target="_blank"
                    rel="noreferrer">
                    Read full announcement{' '}
                    <img src={ArrowRight} alt="arrow right" />
                </a>
            </div>
        </RedeemTemplate>
    );
};
