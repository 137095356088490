import { DelistContext } from 'contexts/delistContext';
import {
    RedeemFormContext,
    RedeemFormContextTypes,
} from 'contexts/redeemFormContext';
import { useCallback, useContext, useMemo, useState } from 'react';
import { api } from 'services/api/api';
import { AcceptBasicStageRequestDTO } from 'services/generated/base';
import { useForm, useModal, useSteps } from './RedeemFormContextProvider.hooks';
import {
    ModalIdType,
    RedeemFormContextProps,
} from './RedeemFormContextProvider.types';
import { uploadTor } from './utils';

export const RedeemFormContextProvider = ({
    children,
}: RedeemFormContextProps) => {
    const { currentStep, steps, findStep, kycUrl } = useSteps();
    const { modalId, setModalId, handleCloseModal, handleOpenModal } =
        useModal();

    const {
        formChecks,
        formInputs,
        formMail,
        formTickets,
        handleAgreeInput,
        saveForm,
        handleFormSend,
        checkError,
        formError,
    } = useForm();

    const [stageLoading, setStageLoading] = useState(false);
    const [torUploadError, setTorUploadError] = useState(false);

    const { passedStages, fetchPassedStages } = useContext(DelistContext);

    const openModal = useCallback(
        (id: ModalIdType) => {
            const step = findStep(id);
            const statuses = step?.clickStatuses
                ? step.clickStatuses
                : ['pending'];
            const isClickable = statuses.includes(step?.status);

            if (step && isClickable) {
                handleOpenModal(id);
            }
        },
        [findStep, handleOpenModal],
    );

    const acceptBasicStage = useCallback(
        async (stage: AcceptBasicStageRequestDTO['stage']) => {
            setStageLoading(true);

            try {
                await api.delist.acceptBasicStage({
                    stage,
                });
                fetchPassedStages();
                setStageLoading(false);
                handleCloseModal();
            } catch {
                setStageLoading(false);
            }
        },
        [fetchPassedStages, handleCloseModal],
    );

    const handleKycInstructionApprove = useCallback(
        (check: boolean) => {
            if (check) {
                acceptBasicStage('instruction_passed');
                return;
            }
            handleCloseModal();
        },
        [acceptBasicStage, handleCloseModal],
    );

    const handleKycApprove = useCallback(() => {
        window.open(kycUrl, '_self');
    }, [kycUrl]);

    const handleDownloadTor = useCallback(() => {
        if (!passedStages.includes('tor_downloaded')) {
            acceptBasicStage('tor_downloaded');
        }
    }, [acceptBasicStage, passedStages]);

    const handleUploadTor = useCallback(
        (file: File) => {
            setStageLoading(true);

            uploadTor(file)
                .then(() => {
                    fetchPassedStages();
                    setStageLoading(false);
                    handleCloseModal();
                })
                .catch(() => {
                    setTorUploadError(true);
                    setStageLoading(false);
                });
        },
        [fetchPassedStages, handleCloseModal],
    );

    const handleAcceptTerms = useCallback(() => {
        acceptBasicStage('terms_accepted');
    }, [acceptBasicStage]);

    const handleAcceptLateWithdrawal = useCallback(() => {
        acceptBasicStage('redemption_acknowledge_accepted');
    }, [acceptBasicStage]);

    const handleAcceptAnnouncement = useCallback(() => {
        acceptBasicStage('announcement_accepted');
    }, [acceptBasicStage]);

    const obj = useMemo<RedeemFormContextTypes>(
        () => ({
            steps,
            currentStep,
            handleOpenModal: openModal,
            handleKycApprove,
            handleDownloadTor,
            handleUploadTor,
            handleAgreeInput,
            handleAcceptTerms,
            handleAcceptLateWithdrawal,
            handleAcceptAnnouncement,
            handleCloseModal,
            modalId,
            setModalId,
            handleKycInstructionApprove,
            saveForm,
            formChecks,
            formMail,
            formTickets,
            formInputs,
            handleFormSend,
            checkError,
            formError,
            stageLoading,
            torUploadError,
            setTorUploadError,
        }),
        [
            setModalId,
            currentStep,
            openModal,
            handleKycApprove,
            handleDownloadTor,
            handleUploadTor,
            handleAgreeInput,
            handleAcceptTerms,
            handleAcceptLateWithdrawal,
            handleAcceptAnnouncement,
            steps,
            modalId,
            handleCloseModal,
            handleKycInstructionApprove,
            saveForm,
            formChecks,
            formMail,
            formTickets,
            formInputs,
            handleFormSend,
            checkError,
            formError,
            stageLoading,
            torUploadError,
        ],
    );

    return (
        <RedeemFormContext.Provider value={obj}>
            {children}
        </RedeemFormContext.Provider>
    );
};
