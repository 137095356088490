import BigNumber from 'bignumber.js';
import { RedeemStatusType } from 'components/redeem/button/redeemBtn.types';
import { initialSteps } from 'components/redeem/content/RedeemFormContextProvider/RedeemFormContenxtProvider.data';
import { ModalIdType } from 'components/redeem/formBox/formBox.types';
import { FormButtonType } from 'components/redeem/formSteps/formSteps.types';
import { useCallback, useEffect, useState } from 'react';
import { api } from 'services/api/api';
import { PassedStagesResposeDTO } from 'services/generated/base';
import { makeCancelablePromise } from 'utils/cancelablePromise';
import { errorOnStatus } from 'utils/errorOnStatus';
import { DataType, KycStatus } from './DelistContextProvider.types';

export const useKyc = () => {
    const [kycStatus, setKycStatus] = useState<KycStatus>(null);
    const [kycLoading, setKycLoading] = useState(false);
    const [kycUrl, setKycUrl] = useState(null);

    const setStatus = useCallback((status: number, url: string) => {
        const isKycProcessing = localStorage.getItem('KYC_PROCESS') === 'true';

        switch (status) {
            case 0:
                setKycStatus('APPROVED');
                break;
            case 1: {
                setKycUrl(url);
                if (isKycProcessing) {
                    setKycStatus('IN_PROGRESS');
                    break;
                }
                setKycStatus('NEEDED');
                break;
            }
            case 2:
                setKycStatus('APPROVED');
                break;
            case 3:
                setKycStatus('DECLINED');
                break;
            default:
                setKycStatus('NEEDED');
                setKycUrl(url);
        }
    }, []);

    const fetchKyc = useCallback(
        (showLoading = true) => {
            if (showLoading) setKycLoading(true);

            const splitedHref = window.location.href.split('#')[1];

            if (splitedHref === 'kyc-submitted') {
                setKycStatus('IN_PROGRESS');
                localStorage.setItem('KYC_PROCESS', 'true');
            }

            api.kyc().then(res => {
                setStatus(res.kycStatus, res.kycUrl);
                if (showLoading) setKycLoading(false);
            });
        },
        [setStatus],
    );

    return {
        fetchKyc,
        kycStatus,
        kycLoading,
        kycUrl,
    };
};

export const useTest = () => {
    const [formStages, setFormStages] =
        useState<FormButtonType[]>(initialSteps);
    const [isFormPassed, setIsFormPassed] = useState(false);

    const setStatus = useCallback(
        (id: ModalIdType, status: RedeemStatusType) => {
            setFormStages(prev =>
                prev.map(redeemButton => {
                    if (id === redeemButton.modalId) {
                        return {
                            ...redeemButton,
                            status,
                        };
                    }

                    return redeemButton;
                }),
            );
        },
        [],
    );

    const setStages = useCallback(
        (stages: PassedStagesResposeDTO['stages']) => {
            if (stages.length === 0 || !stages.includes('form_passed')) {
                setIsFormPassed(false);
                return;
            }

            setIsFormPassed(true);

            setFormStages(prev => {
                let isActiveButtonSet = false;

                return prev.map(btn => {
                    const isStagePassed = stages.find(
                        stage => stage === btn.completedId,
                    );

                    if (!isStagePassed) {
                        const newStatus =
                            !isActiveButtonSet && btn.status !== 'waiting'
                                ? btn.status
                                : 'pending';

                        const object = {
                            ...btn,
                            status: isActiveButtonSet ? 'waiting' : newStatus,
                        };
                        isActiveButtonSet = true;
                        return object;
                    }

                    return {
                        ...btn,
                        status: 'passed',
                    };
                }) as FormButtonType[];
            });
        },
        [],
    );

    return {
        formStages,
        isFormPassed,
        setStages,
        setStatus,
    };
};

export const useData = (
    isStageFetched: boolean,
    setShowStcLow: (value: boolean) => void,
    setShowError: (value: boolean) => void,
) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [data, setData] = useState<DataType>(null);
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalStc, setTotalStc] = useState(null);

    useEffect(() => {
        if (!isStageFetched) return;

        setLoading(true);
        const cancelablePromise = makeCancelablePromise(api.delist.balances());

        cancelablePromise
            .then(res => {
                errorOnStatus(res.value.status, setShowError);
                const newData = Object.entries(res.value.balances).reduce(
                    (acc, [key, value]) => {
                        if (typeof value === 'object' && value !== null) {
                            acc[key] = Object.entries(value).reduce(
                                (subAcc, [subKey, subValue]) => {
                                    subAcc[subKey] = new BigNumber(
                                        subValue as string,
                                    );
                                    return subAcc;
                                },
                                {},
                            );
                        } else {
                            acc[key] = new BigNumber(value as string);
                        }
                        return acc;
                    },
                    {},
                ) as DataType;

                const totalStc = new BigNumber(
                    res.value.balances?.bonuses?.amount ?? 0,
                )
                    .plus(new BigNumber(res.value.balances?.other?.amount ?? 0))
                    .plus(
                        new BigNumber(
                            res.value.balances?.purchase?.amount ?? 0,
                        ),
                    )
                    .plus(
                        new BigNumber(res.value.balances?.staking?.amount ?? 0),
                    );

                if (
                    new BigNumber(res.value?.balances?.usdTotal).isLessThan(2)
                ) {
                    setShowStcLow(true);
                    setLoading(false);
                    return;
                }

                setTotalStc(totalStc);
                setLoading(false);
                setEmail(res.value.email);
                setData(newData);
            })
            .catch(() => {
                errorOnStatus(400, setShowError);
                setLoading(false);
            });

        return () => {
            cancelablePromise.cancel();
        };
    }, [setShowStcLow, setShowError, isStageFetched]);

    return { data, totalStc, email, loading };
};
