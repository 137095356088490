/* eslint-disable sonarjs/no-duplicate-string */
import Lock from 'assets/img/lock-alt.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { modalScreenTransition } from 'utils/pageVariants';
import styles from './modal.module.scss';

import Modal from 'react-modal';

import ModalFooter from 'components/global/modal/footer/footer';
import ModalHeader from 'components/global/modal/header/header';
import ModalSteps from 'components/global/modal/steps/steps';

import { BlockModal } from 'components/global/blockModal/BlockModal';
import { DelistContext } from 'contexts/delistContext';
import { useStepper } from 'hooks/useStepper';
import { api } from 'services/api/api';
import { makeCancelablePromise } from 'utils/cancelablePromise';
import { formatDate } from 'utils/formatDate';
import { useValidate } from './hooks/useValidate';
import { StepperMove, WithdrawModalProps } from './modal.types';
import { getSteps } from './utils/getSteps';
import { View } from './view';

export const RedeemWithdraw = ({
    unlockTime,
    close,
    open,
    value,
    email,
}: WithdrawModalProps) => {
    const [address, setAddress] = useState('');
    const { setShowError } = useContext(DelistContext);
    const [scannerShow, setScannerShow] = useState(false);
    const [fee, setFee] = useState({
        value: 0,
        usd: 0,
    });

    useEffect(() => {
        const cancelablePromise = makeCancelablePromise(api.delist.fee());

        cancelablePromise
            .then(res => {
                setFee({
                    value: parseFloat(res.value.fee),
                    usd: parseFloat(res.value.fee),
                });
            })
            .catch(() => {
                if (open) setShowError(true);
            });

        return () => {
            cancelablePromise.cancel();
        };
    }, [setShowError, open]);

    const [checked, setChecked] = useState([false, false, false]);
    const [response, setResponse] = useState('');

    const [errors, setErrors] = useState({});

    const [animatedX, setAnimatedX] = useState(0);
    const [modalErrorText, setModalErrorText] = useState('');

    const [withdrawLoading, setWithdrawLoading] = useState(false);

    const steps = useMemo(() => getSteps('usdc'), []);

    const {
        currentStep,
        stepNumber,
        goToNextStep,
        goToPreviousStep,
        resetStepper,
    } = useStepper(0, steps);

    const { validateAll } = useValidate(
        address,
        value,
        value,
        fee.value,
        checked,
    );

    const changeActive = useCallback(
        (move: StepperMove) => {
            switch (move) {
                case 'next':
                    setAnimatedX(20);
                    goToNextStep();
                    break;
                case 'back':
                    setAnimatedX(-20);
                    goToPreviousStep();
                    break;
                case 'again':
                    setAnimatedX(-20);
                    resetStepper();
                    break;
            }
        },
        [goToNextStep, goToPreviousStep, resetStepper],
    );

    const withdrawOtherCurrencies = useCallback(
        (code: string) => {
            setErrors({});

            try {
                validateAll();

                if (!withdrawLoading) {
                    setWithdrawLoading(true);

                    api.delist
                        .withdraw({
                            address: address,
                            verificationCode: code,
                        })
                        .then(() => {
                            setWithdrawLoading(false);
                            setResponse('success');
                            changeActive('next');
                        })
                        .catch(error => {
                            setWithdrawLoading(false);
                            setResponse('declined');
                            setModalErrorText(error?.body?.message || '');
                            changeActive('next');
                        });
                }
            } catch (error) {
                setErrors(error);
            }
        },
        [address, withdrawLoading, changeActive, validateAll],
    );

    const tryAgain = useCallback(() => {
        setResponse('');
        changeActive('again');
    }, [changeActive]);

    const closeScanner = useCallback(() => {
        setScannerShow(false);
    }, []);

    const resetModal = useCallback(() => {
        resetStepper();
        setResponse('');
        setAddress('');
        setScannerShow(false);
        setWithdrawLoading(false);
        setErrors({});
        close(false);
        setChecked([false, false, false]);
    }, [close, resetStepper]);

    const handleNextClick = useCallback(() => {
        setErrors({});

        try {
            validateAll();

            if (scannerShow) {
                closeScanner();
            } else {
                changeActive('next');
            }
        } catch (e) {
            setErrors(e);
        }
    }, [changeActive, scannerShow, validateAll, closeScanner]);

    const formattedDate = useMemo(
        () => formatDate(unlockTime, 'en-GB', 'long', false),
        [unlockTime],
    );

    const clearErrors = useCallback(() => {
        setErrors({});
    }, []);

    return (
        <Modal
            className={`modal-body ${styles.modal}`}
            isOpen={open}
            preventScroll={true}
            ariaHideApp={false}
            overlayClassName="modal-overlay"
            onRequestClose={() => resetModal()}
            closeTimeoutMS={300}
            shouldCloseOnOverlayClick={true}>
            {!!unlockTime && (
                <BlockModal
                    icon={Lock}
                    text={`Your withdrawals are locked due to change password. You will be
                            able to perform withdraw at ${formattedDate}.`}
                />
            )}
            {currentStep?.id !== 'endScreen' && (
                <>
                    <ModalHeader
                        text={`Redeem STC and receive USDC`}
                        onClose={() => resetModal()}
                    />
                    <ModalSteps
                        steps={steps}
                        stepNumber={stepNumber}
                        exclude={['endScreen']}
                    />
                </>
            )}
            <AnimatePresence exitBeforeEnter>
                <motion.div
                    className={styles.modalContent}
                    initial={{
                        opacity: 0,
                        x: animatedX,
                    }}
                    animate={{
                        opacity: 1,
                        x: 0,
                    }}
                    transition={modalScreenTransition}
                    key={currentStep.id}>
                    <View
                        step={currentStep}
                        amount={value}
                        address={address}
                        setAddress={setAddress}
                        scannerShow={scannerShow}
                        setScannerShow={setScannerShow}
                        errors={errors}
                        fee={fee}
                        newWithdraw={withdrawOtherCurrencies}
                        isLoading={withdrawLoading}
                        type={response}
                        tryAgain={tryAgain}
                        close={resetModal}
                        modalErrorText={modalErrorText}
                        email={email}
                        check={checked}
                        setCheck={setChecked}
                        clearErrors={clearErrors}
                    />
                </motion.div>
            </AnimatePresence>
            {currentStep?.id !== 'endScreen' && (
                <ModalFooter
                    clickNext={handleNextClick}
                    clickBack={() => changeActive('back')}
                    hideNext={currentStep?.id === 'auth'}
                    hideBack={currentStep?.id === 'details'}
                    nextContent={scannerShow ? 'Close' : ''}
                    isLoading={withdrawLoading}
                />
            )}
        </Modal>
    );
};
